import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View, Dimensions, Image, Animated, Modal, Pressable, ActivityIndicator, TouchableOpacity, TextInput } from 'react-native';
import React, { useRef, useEffect, useState } from 'react';
import TopNa from './src/Router/TopNa';
import { SafeAreaView } from 'react-native';
import { Auth, graphqlOperation, API} from 'aws-amplify';
import { getUserInfo } from './src/graphql/queries';

import Amplify from 'aws-amplify';
import awsExports from './src/aws-exports';
Amplify.configure(awsExports)

const {width, height} = Dimensions.get('window')

export default function App() {

  const mainColour = '#FF4F00'

  const [choose, setChoose] = useState('main')
  const [emailLogin, ChangeEmailLogin] = useState('');
  const [emailPassword, ChangeEmailPassword] = useState('');

  const [userDeta, setUserDeta] = useState();

 const checkAuth = async()=>{
  

        try{
            const userAuthenticated =  await Auth.currentAuthenticatedUser();
            const userSub = userAuthenticated.attributes.sub;

            const gettingUser = await API.graphql(graphqlOperation(getUserInfo, { id: userSub}))
            const gettingUserData = gettingUser.data.getUserInfo

            setUserDeta(gettingUserData)

            console.log(gettingUserData?.name)

            setChoose('don')

        } catch (e){
          console.log(e),
          setChoose('main')
        
        }
        


    }

    const fadeAnim = useRef(new Animated.Value(0)).current;


    const fadeIn = () => {
    // Will change fadeAnim value to 1 in 5 seconds
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 2500,
      useNativeDriver: true,
    }).start();
  };

   const fadeOut = () => {
    // Will change fadeAnim value to 0 in 3 seconds
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 3000,
    }).start();
  };

  useEffect(() => {
    checkAuth()
    fadeIn()
  }, [])


  async function signInS() {
    Auth.signIn(emailLogin, emailPassword).then(user => {
      console.log('signedin'),
      setChoose('logged')
    })
  }


  if(choose === 'main'){
    return(
      <View style={{flex: 1, backgroundColor: 'white', alignItems: 'center', justifyContent: 'center'}}>
        
        <View style={{width: width / 2, height: width /3, borderWidth: 2, borderColor: '#d1d1d1', borderRadius: 10}}>
          
          <View style={{alignSelf: 'center'}}> 
            <Image source={require('./assets/logo.png')} style={{width: 150, height: 100, resizeMode: 'contain', marginTop: 25}} />
            <Text style={{fontSize: 25, marginTop: 10, fontWeight: '700'}}>Login</Text>
          </View>

          <View style={{marginTop: 15, marginLeft: 30}}>
            <View>
              <Text>E-Mail Address</Text>
              <TextInput onChangeText={ChangeEmailLogin} value={emailLogin} style={{height: 45, width: width/4, marginTop: 10, borderWidth: 1, borderColor: '#d1d1d1', paddingLeft: 10, borderRadius: 10}} />
            </View>

            <View style={{marginTop: 15}}>
              <Text>Password</Text>
              <TextInput onChangeText={ChangeEmailPassword} value={emailPassword} style={{height: 45, width: width/4, marginTop: 10, borderWidth: 1, borderColor: '#d1d1d1', paddingLeft: 10, borderRadius: 10}} />
            </View>

            <View style={{marginTop: 15}}>
              <TouchableOpacity onPress={()=> signInS()} style={{width: 150, height: 45, borderRadius: 10, backgroundColor: mainColour, alignItems: 'center', justifyContent: 'center'}}>
                <Text style={{fontSize: 16, color: 'white', fontWeight: 'bold'}}>Sign In</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    )
  }
  


  // if(width === 800 || width > 800){
  //   return(
  //           <View style={{flex: 1}}>
  //       <View style={{backgroundColor:'white', flexDirection: 'row', height: 50, marginLeft: 30, alignItems: 'center'}}>

  //         <Animated.View style={{justifyContent: 'flex-start',opacity: fadeAnim}}>
  //                     <Image style={{width: 100, height: 50, resizeMode: 'contain', paddingTop: 10 }}  source={require('./assets/logo.png')} />
  //                   </Animated.View>
  //           <View>
  //             <Animated.View style={{marginLeft: 30, opacity: fadeAnim}}>
  //               <Text>Sign In / Register</Text>
  //             </Animated.View>
  //         </View>
  //       </View>
        
  //       <TopNa/>
        
  //     </View>
  //   )
  // } else if (width === 799 || width < 799){
  //   return(
  //     <View>
  //       <Text>Mobile {width}</Text>
  //     </View>
  //   )
  // }

   return(
            <View style={{flex: 1}}>
        <View style={{backgroundColor:'white', flexDirection: 'row', height: 50, marginLeft: 15, alignItems: 'center', marginTop: 15}}>

          <Animated.View style={{justifyContent: 'flex-start',opacity: fadeAnim}}>
                      <Image style={{width: 100, height: 50, resizeMode: 'contain', paddingTop: 10 }}  source={require('./assets/logo.png')} />
                    </Animated.View>
            <View>
              {/* <Animated.View style={{marginLeft: 30, opacity: fadeAnim}}>
                <Text>Sign In / Register</Text>
              </Animated.View> */}
          </View>
        </View>
        
        <TopNa/>
        
      </View>
    )


}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
