import { View, Text, Pressable, TouchableOpacity, Image, Dimensions, FlatList, TextInput, StyleSheet} from 'react-native'
import React, {useState, useEffect} from 'react'
import { useNavigation, useRoute } from '@react-navigation/native';
import { Ionicons, MaterialCommunityIcons, Octicons, AntDesign, Entypo, MaterialIcons  } from '@expo/vector-icons'; 
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { onCreatedChatMessage } from '../../graphql/subscriptions';

const {width, height} = Dimensions.get('window')
const ChatScreen = () => {

    const navigation = useNavigation();
    const route = useRoute().params

    const mainColour = '#FF4F00';

     const [messageList, setMessageList] = useState([]);
    const [chatUpdt, setChatUpdt] = useState([]);
    const [message, setMessage] = useState('');
    const [uid, setUid] = useState('');

    const fetchMessages = async() =>{

        // const userAuthenticated =  await Auth.currentAuthenticatedUser();
        // const userSub = userAuthenticated.attributes.sub;
        // setUid(userSub)


        const allTodos = await API.graphql({ query: queries.listChatMessages, variables: 
        {
            filter :   
                { 
                    chatRoomID: 
                        {eq: 
                            (route?.chatRoom)
                        },
                                                            
                        }
        } });

        const todos = allTodos.data.listChatMessages.items

        setMessageList(todos)
    }

     let subsUpdateCreate;

    function setUpSus(){
        subsUpdateCreate = API.graphql(graphqlOperation(onCreatedChatMessage)).subscribe( {next: (daraaaaa) => {
         setChatUpdt(daraaaaa)
     }, }) 
    }


    useEffect(() => {
      fetchMessages()

      setUpSus()

      return() =>{
        subsUpdateCreate.unsubscribe();
      }
    }, [chatUpdt])
    

    const rednerMessages = ({item})=>{
        if('Admin' === item?.sender){
        return(
            <TouchableOpacity style={{alignItems: 'flex-end'}}>
                <View style={{padding: 15, backgroundColor: '#97deb1', width: width / 4, marginTop: 20, marginRight: 15, borderTopLeftRadius: 15, borderBottomLeftRadius: 30, borderTopRightRadius: 30, alignItems: 'flex-end'}}>
                    <Text>{item?.message}</Text>
                </View>
                <View style={{marginRight: 15, marginTop: 5}}>
                    <Text style={{fontSize: 10}}>{item?.createdAt}</Text>
                </View>
            </TouchableOpacity>
        )
        } else {
            return(
                <TouchableOpacity style={{}}>
                <View style={{padding: 15, backgroundColor: '#a5b0c2', width: width / 4, marginTop: 20, marginLeft: 15, borderTopLeftRadius: 30, borderBottomRightRadius: 30, borderTopRightRadius: 15, alignItems: 'flex-end'}}>
                    <Text>{item?.message}</Text>
                </View>
                <View style={{marginRight: 0, marginTop: 5, marginLeft:15}}>
                    <Text style={{fontSize: 10}}>{item?.createdAt}</Text>
                </View>
            </TouchableOpacity>
            )
        }
    }

     const sendMessage = async() =>{

        const userAuthenticated =  await Auth.currentAuthenticatedUser();
        const userSub = userAuthenticated.attributes.sub;

        const messageInput = {
            message: message,
            sender: 'Admin',
            chatRoomID: route?.chatRoom
        }


        try {
             await API.graphql({query: mutations.createChatMessage, variables: {input: messageInput}}).then((e)=>{
                setMessage('')
             })
        } catch (error) {
            console.error(error)
        }
    }


     messageList.sort(function(a, b) {

        const newerDate = String(a.createdAt).replace(/\D/g, "")
        const newerB = String(b.createdAt).replace(/\D/g, "")

    return parseFloat(newerB) - parseFloat(newerDate);
});


  return (
    <View style={{flex: 1, backgroundColor: '#ffffff'}}>
        <View style={{marginTop: 15}}>
            <TouchableOpacity style={{marginLeft: 15, flexDirection: 'row'}} onPress={()=> navigation.goBack()}>
                <Ionicons name="arrow-back-outline" size={24} color="black" />
                <Text style={{fontSize: 18, fontWeight: '600', marginLeft: 15}}>Chat to Customer</Text>
            </TouchableOpacity>

            <View style={{marginTop: 15, flexDirection: 'row'}}>
                <View style={{width: '60%', paddingLeft: 0, paddingTop: 0, borderRightWidth: 0.5, borderColor: '#d1d1d1', height: height, marginTop: 15, borderTopWidth: 0.5, borderColor: '#d1d1d1', alignItems: 'center'}}>
                   
                   <View style={{width: '100%',}}>
                    <FlatList 
                        data={messageList}
                        renderItem={rednerMessages}
                    />
                    </View>
                </View>

                <View style={{width: '40%', marginLeft: 25, paddingTop: 25}}>
                    <TextInput value={message} onChangeText={setMessage} multiline={true} placeholder='Write your message' style={{height: 300, width: 300, borderRadius: 10, borderWidth: 1, borderColor: '#d1d1d1', padding: 15}}/>
               
                    <TouchableOpacity onPress={()=> sendMessage()} style={{width: 300, height: 50, marginTop: 15, backgroundColor: mainColour, borderRadius: 10, alignItems: 'center', justifyContent: 'center'}}>
                        <Text style={{color: 'white', fontWeight: 'bold', fontSize: 16}}>Send Message</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    </View>
  )
}

export default ChatScreen

const styles = StyleSheet.create({})