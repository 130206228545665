import { View, Text, Pressable, TouchableOpacity, Image, Dimensions, FlatList, TextInput } from 'react-native'
import React, {useState, useEffect} from 'react';
import { Ionicons, MaterialCommunityIcons, Octicons, AntDesign, Entypo, MaterialIcons  } from '@expo/vector-icons'; 
import { useNavigation, useRoute } from '@react-navigation/native';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { onAddedOrder, onUpdatedOrder, onDeletedOrder } from '../../graphql/subscriptions';
import { storage } from '../../../config';
import * as ImagePicker from 'expo-image-picker';

import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

const AddVoucher = () => {

    const navigation = useNavigation();
    const route = useRoute().params
    const mainColour = '#FF4F00'

     const [UserList, setUserList] = useState([])
     const [name, setName] = useState()
     const [amount, setAmount] = useState()
     const [code, setCode] = useState()
     const [plate, setPlate] = useState([])

    const [imgUrl, setImgUrl] = useState()
    const [image, setImage] = useState(null);


    async function fetchUsers() {

        const allTodos = await API.graphql({ query: queries.listUserInfos});

        const todos = allTodos.data.listUserInfos.items
        setUserList(todos)


     }


      useEffect(() => {
      fetchUsers()

    }, [])

     const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    console.log(result);

    // handleUpload(result)

    uploadImage(result)

    // setImageUri(result?.uri)
      
    if (!result.cancelled) {
      setImage(result?.uri);
    }
  };


  const uploadImage = async(pickerResult)=> {


        const blob = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function(){
                resolve(xhr.response);
            };
            xhr.onerror = function(){
                reject(new TypeError('Network request failed'));
            };
            xhr.responseType = 'blob';
            xhr.open('GET', pickerResult?.uri, true);
            xhr.send(null)

        });



        const datee = new Date().toISOString()

        const metadata = {
            contentType: 'image/jpeg',
        };

        const storageRef = ref(storage, datee);

        
        uploadBytes(storageRef, blob, metadata).then((snapshot) => {
        console.log('Uploaded')
        getDownloadURL(snapshot.ref).then((downloadURL) => {
            setImgUrl(downloadURL)
            console.log('d')
            // console.log('File available at', downloadURL);
        })
        }).catch((error) => {
            console.log(error.message)
        })    
       
    }

     const addVoucher = async()=>{
         const inputNew = {
              id: code,
              name: name,
              amount: amount,
            }


        await API.graphql({query: mutations.createVouchers, variables: {input: inputNew}}).then((e)=> {
           navigation.goBack()
        })
    
    }

  return (
    <View style={{flex: 1, backgroundColor: 'white'}}>
        <View style={{marginTop: 15}}>
            <TouchableOpacity style={{marginLeft: 15}} onPress={()=> navigation.goBack()}>
                <Ionicons name="arrow-back-outline" size={24} color="black" />
            </TouchableOpacity>


        </View>

        <View style={{marginTop: 15, borderTopWidth: 1, borderColor: '#d1d1d1', width: '100%'}}>
            <View style={{margin: 15}}>
                <Text style={{fontSize: 18, fontWeight: 'bold'}}>Add Voucher</Text>

            </View>
        </View>

        <View style={{flexDirection:'row'}}>

            <View style={{marginLeft: 200, flexDirection: 'row'}}>

                <View>

            
                {/* first name */}
                    <View>
                        <Text style={{marginBottom: 5}}>Voucher Name</Text>
                        <TextInput value={name} onChangeText={setName} style={{height: 45, width: 200, borderRadius: 5, borderWidth: 1, borderColor: '#d1d1d1', paddingLeft: 15}} placeholder={route?.name} />
                    </View>

                    <View style={{marginTop: 10}}>
                        <Text style={{marginBottom: 5}}>Amount</Text>
                        <TextInput value={amount} onChangeText={setAmount}  style={{height: 45, width: 200, borderRadius: 5, borderWidth: 1, borderColor: '#d1d1d1', paddingLeft: 15}} placeholder={route?.surname} />
                    </View>

                    <View style={{marginTop: 10}}>
                        <Text style={{marginBottom: 5}}>Code</Text>
                        <TextInput  value={code} onChangeText={setCode}  style={{height: 45, width: 200, borderRadius: 5, borderWidth: 1, borderColor: '#d1d1d1', paddingLeft: 15}} placeholder='Description' />
                    </View>

                </View>

                <View style={{marginLeft: 30}}>
                    <TouchableOpacity onPress={()=> addVoucher()} style={{width: 200, marginTop: 35, borderRadius: 5, height: 45, backgroundColor: mainColour, alignItems: 'center', justifyContent: 'center'}}>
                        <Text style={{color: 'white'}}>Create Voucher</Text>
                    </TouchableOpacity>

                </View>
            </View>
        </View>
    </View>
  )
}

export default AddVoucher