import { View, Text, Pressable, TouchableOpacity, Image, Dimensions, FlatList, TextInput, ScrollView, } from 'react-native'
import React, {useState, useEffect} from 'react'
import { useNavigation, useRoute } from '@react-navigation/native';
import { Ionicons, MaterialCommunityIcons, Octicons, AntDesign, Entypo, MaterialIcons  } from '@expo/vector-icons'; 
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries'
import * as mutations from '../../graphql/mutations'
const {width, height} = Dimensions.get('window')
const TopActivity = () => {
 
    const navigation = useNavigation();
    const route = useRoute().params

    const {orderDet} = route

    const mainColour = '#FF4F00';

    const [driverSelect, setDriverSelect] = useState([])
    const [OrderProduct, setOrderProduct] = useState([])
    const [driver, setDriver] = useState([])

    const date = new Date();


     async function fetchUsers() {

        const allOrders = await API.graphql({ query: queries.listOrderProducts, variables:
           { filter : {
             orderID: {eq: orderDet?.id},
           },

           }});

        const todos = allOrders.data.listOrderProducts.items
        setOrderProduct(todos)



        const allDrivers = await API.graphql({ query: queries.listDriverLists});

        const todot = allDrivers.data.listDriverLists.items
        setDriver(todot)

     }

     useEffect(() => {
      fetchUsers()

    }, [])



    const renderList = ({item})=>{
        return(
            <View style={{marginRight: 10}}>
                <View>
                    <Image style={{width: 80, height: 80, borderRadius: 5}} source={{uri: item?.image}} />
                </View>
                <View>
                    <Text>{item?.title}</Text>
                    <Text>Qty : {item?.quantity}</Text>
                    <Text>P{Number(item?.price).toFixed(2)}</Text>
                </View>
            </View>
        )
    }

    const renderDrivers = ({item}) => {
        return(
            <View style={{marginRight: 15}}>
                <TouchableOpacity onPress={()=> setDriverSelect(item)} style={{ width: '100%'}}>
                    <Image style={{height: 80, width: 80, borderRadius: 10, borderWidth: 3, borderColor: item?.id == driverSelect?.id ? mainColour : 'white'}} source={{uri: item?.image}} />
                    <Text style={{fontSize: 10}}>{item?.name} {item?.surname}</Text>
                </TouchableOpacity>

            </View>
        )
    }

    const acceptOrder = async() =>{

        if(driverSelect === null){
            return
        }


        const AcceptInput = {
              id: orderDet?.id,
              statusC: 'Accepted',
              driverID: driverSelect?.id,
              driverSub: driverSelect?.userID,
              car: driverSelect?.car,
              model: driverSelect?.model,
              plate: driverSelect?.carPlate,
              driverName: driverSelect?.name,
              driverSurname: driverSelect?.surname,
              driverImage: driverSelect?.image
            }

            const NotificationInput = {
              title: "Order Accepted",
              message: 'Your order number :' + orderDet?.id + ' has been Accepted. ' + driverSelect?.name + ' will be delivering',
              backgroundColor: mainColour,
              letter: 'O',
              author: "First Team",
              read: false,
              date: date.getDate,
              time: date.getTime,
              userSub: orderDet?.userSub
              
            }
            const ChatRoomInput = {
              id: orderDet?.id,
              driverID: driverSelect?.userID,                            
            }



            try {
                 await API.graphql({query: mutations.updateOrder, variables: {input: AcceptInput}}).then((e)=> 
                    {
                        API.graphql({query: mutations.createChatRoom, variables: {input: ChatRoomInput}}).then((ee)=>{
                            API.graphql({query: mutations.createNotificationsList, variables: {input: NotificationInput}}).then((ey)=>{
                                navigation.goBack()
                            })
                        })
                        
                    }
        
        )
            } catch (error) {
                console.error(error)
            }

       
    }



    const declineOrder = async() =>{
        const declinedInput = {
              id: orderDet?.id,
              statusC: 'Declined',
              statusB: 'Cancelled',            
            }

            const CancelledNotificationInput = {
              title: "Order Declined",
              message: 'Your order number :' + orderDet?.id + ' has been Declined.',
              backgroundColor: mainColour,
              letter: 'O',
              author: "First Team",
              read: false,
              date: date.getDate,
              time: date.getTime,
              userSub: orderDet?.userSub
              
            }

        await API.graphql({query: mutations.updateOrder, variables: {input: declinedInput}}).then((e)=> 
        {
            API.graphql({query: mutations.createNotificationsList, variables: {input: CancelledNotificationInput}}).then((e)=> {
                navigation.goBack()
            })
            
        }
        )
    }
    

  return (
    <View style={{flex: 1, backgroundColor: '#ffffff'}}>
        <View style={{marginTop: 15}}>
            <TouchableOpacity style={{marginLeft: 15}} onPress={()=> navigation.goBack()}>
                <Ionicons name="arrow-back-outline" size={24} color="black" />
            </TouchableOpacity>

            <View style={{marginTop: 15, flexDirection: 'row'}}>

                <View style={{width: '33%', paddingLeft: 0, paddingTop: 0, borderRightWidth: 0.5, borderColor: '#d1d1d1', height: height, marginTop: 15, borderTopWidth: 0.5, borderColor: '#d1d1d1', alignItems: 'center'}}>
                    <View style={{alignSelf: 'center', marginBottom: 0, borderBottomWidth: 0.5, width: '100%', alignItems: 'center', borderColor: '#d1d1d1', height: 60, justifyContent: 'center'}}>
                        <Text style={{fontSize: 18, fontWeight: 'bold'}}>Order Details</Text>
                    </View>

                    <ScrollView style={{}}>

                        <View style={{width: '100%', padding: 15}}>

                                <View style={{flexDirection: 'row'}}>
                                    <Text style={{width: 150}}>Type</Text>
                                    <Text style={{width: 40}}>:</Text>
                                    <Text style={{fontWeight: 'bold'}}>{orderDet?.type || `unAssigned`}</Text>
                                </View>


                                <View style={{flexDirection: 'row', marginTop: 10}}>
                                    <Text style={{width: 150}}>Order #</Text>
                                    <Text style={{width: 40}}>:</Text>
                                    <Text style={{ width: 150}}>{orderDet?.id}</Text>
                                </View>

                                <View style={{flexDirection: 'row', marginTop: 10}}>
                                    <Text style={{width: 150}}>Date</Text>
                                    <Text style={{width: 40}}>:</Text>
                                    <Text style={{}}>{orderDet?.createdAt}</Text>
                                </View>

                                <View style={{flexDirection: 'row', marginTop: 10}}>
                                    <Text style={{width: 150}}>Address</Text>
                                    <Text style={{width: 40}}>:</Text>
                                    <Text style={{}}>{orderDet?.address}</Text>
                                </View>

                                <View style={{flexDirection: 'row', marginTop: 10}}>
                                    <Text style={{width: 150}}>LatLng</Text>
                                    <Text style={{width: 40}}>:</Text>
                                    <Text style={{width: 200}}>{orderDet?.lat} ; {orderDet?.lng}</Text>
                                </View>

                                <View style={{flexDirection: 'row', marginTop: 10}}>
                                    <Text style={{width: 150}}>Order Amount</Text>
                                    <Text style={{width: 40}}>:</Text>
                                    <Text style={{}}>P{Number(orderDet?.orderAmount).toFixed(2)}</Text>
                                </View>

                        </View>

                        <View style={{ paddingLeft: 15}}>
                        
                            <FlatList 
                                horizontal
                                data={OrderProduct}
                                keyExtractor={(item, index) => index.toString()}
                                renderItem={renderList} 
                                style={{marginLeft: 15, width: 400}}
                            />
                        </View>

                    </ScrollView>


                </View>

                <View style={{width: '33%', paddingLeft: 0, paddingTop: 0, borderRightWidth: 0.5, borderColor: '#d1d1d1', height: height, marginTop: 15, borderTopWidth: 0.5, borderColor: '#d1d1d1', alignItems: 'center'}}>
                    <View style={{alignSelf: 'center', marginBottom: 0, borderBottomWidth: 0.5, width: '100%', alignItems: 'center', borderColor: '#d1d1d1', height: 60, justifyContent: 'center'}}>
                        <Text style={{fontSize: 18, fontWeight: 'bold'}}>Assign(ed) Driver</Text>
                    </View>

                    <View style={{marginTop: 15}}>

                        <View style={{marginBottom: 10}}>
                            <Text>
                                Click on driver to assign on the order
                            </Text>
                        </View>
                        <FlatList
                            horizontal
                            data={driver}
                            renderItem={renderDrivers}
                            keyExtractor={(item, index) => index.toString()}
                         />
                    </View>

                    <View style={{marginTop: 15, width: '100%', paddingLeft: 50}}> 
                        <View style={{flexDirection: 'row', alignSelf: 'flex-start', marginLeft: 15}}>
                            <Text style={{width: 100}}>Name</Text>
                            <Text style={{width: 10}}>:</Text>
                            <Text style={{marginLeft: 30}}>{driverSelect?.name || `-`}</Text>
                        </View>

                        <View style={{flexDirection: 'row', alignSelf: 'flex-start', marginLeft: 15, marginTop: 10}}>
                            <Text style={{width: 100}}>Surname</Text>
                            <Text style={{width: 10}}>:</Text>
                            <Text style={{marginLeft: 30}}>{driverSelect?.surname || `-`}</Text>
                        </View>

                        <View style={{flexDirection: 'row', alignSelf: 'flex-start', marginLeft: 15, marginTop: 10}}>
                            <Text style={{width: 100}}>Car</Text>
                            <Text style={{width: 10}}>:</Text>
                            <Text style={{marginLeft: 30}}>{driverSelect?.car || `-`}</Text>
                        </View>

                        <View style={{flexDirection: 'row', alignSelf: 'flex-start', marginLeft: 15, marginTop: 10}}>
                            <Text style={{width: 100}}>Model</Text>
                            <Text style={{width: 10}}>:</Text>
                            <Text style={{marginLeft: 30}}>{driverSelect?.model || `-`}</Text>
                        </View>
                    </View>




                </View>








                <View style={{width: '33%', paddingLeft: 0, paddingTop: 0, borderRightWidth: 0, borderColor: '#d1d1d1', height: height, marginTop: 15, borderTopWidth: 0.5, borderColor: '#d1d1d1', alignItems: 'center'}}>
                    <View style={{alignSelf: 'center', marginBottom: 0, borderBottomWidth: 0.5, width: '100%', alignItems: 'center', borderColor: '#d1d1d1', height: 60, justifyContent: 'center'}}>
                        <Text style={{fontSize: 18, fontWeight: 'bold'}}>Options</Text>
                    </View>
                     <TouchableOpacity onPress={()=> acceptOrder()} style={{ borderBottomWidth: 1, borderColor: '#d1d1d1', width: '100%', height: 60, flexDirection: 'row', borderTopWidth: 0, alignItems: 'center'}}>
                
                <View style={{marginLeft: 15, marginRight: 15, width: 40, height: 30, backgroundColor: 'green', borderRadius: 5, alignItems: 'center', justifyContent: 'center'}}>
                    <MaterialCommunityIcons  name="check" size={20} color="white" />
                </View>
                
                <Text>Accept Order</Text>
                    </TouchableOpacity>

                    <TouchableOpacity onPress={()=> declineOrder() } style={{ borderBottomWidth: 1, borderColor: '#d1d1d1', width: '100%', height: 60, flexDirection: 'row', borderTopWidth: 0, alignItems: 'center'}}>
                
                <View style={{marginLeft: 15, marginRight: 15, width: 40, height: 30, backgroundColor: 'red', borderRadius: 5, alignItems: 'center', justifyContent: 'center'}}>
                    <Entypo name="cross" size={20} color="white" />
                </View>
                
                <Text>Decline Order</Text>
                    </TouchableOpacity>
                </View>
            </View>



        </View>
    </View>
  )
}

export default TopActivity