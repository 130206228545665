/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTodo = /* GraphQL */ `
  mutation CreateTodo(
    $input: CreateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    createTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateTodo = /* GraphQL */ `
  mutation UpdateTodo(
    $input: UpdateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    updateTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteTodo = /* GraphQL */ `
  mutation DeleteTodo(
    $input: DeleteTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    deleteTodo(input: $input, condition: $condition) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const createProductsFirst = /* GraphQL */ `
  mutation CreateProductsFirst(
    $input: CreateProductsFirstInput!
    $condition: ModelProductsFirstConditionInput
  ) {
    createProductsFirst(input: $input, condition: $condition) {
      id
      title
      brand
      price
      image
      description
      category
      cylinder
      available
      createdAt
      updatedAt
    }
  }
`;
export const updateProductsFirst = /* GraphQL */ `
  mutation UpdateProductsFirst(
    $input: UpdateProductsFirstInput!
    $condition: ModelProductsFirstConditionInput
  ) {
    updateProductsFirst(input: $input, condition: $condition) {
      id
      title
      brand
      price
      image
      description
      category
      cylinder
      available
      createdAt
      updatedAt
    }
  }
`;
export const deleteProductsFirst = /* GraphQL */ `
  mutation DeleteProductsFirst(
    $input: DeleteProductsFirstInput!
    $condition: ModelProductsFirstConditionInput
  ) {
    deleteProductsFirst(input: $input, condition: $condition) {
      id
      title
      brand
      price
      image
      description
      category
      cylinder
      available
      createdAt
      updatedAt
    }
  }
`;
export const createUserInfo = /* GraphQL */ `
  mutation CreateUserInfo(
    $input: CreateUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    createUserInfo(input: $input, condition: $condition) {
      id
      name
      surname
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      driver
      activeVoucherName
      activeVoucherCode
      discountPercentage
      discountAmount
      discountQty
    }
  }
`;
export const updateUserInfo = /* GraphQL */ `
  mutation UpdateUserInfo(
    $input: UpdateUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    updateUserInfo(input: $input, condition: $condition) {
      id
      name
      surname
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      driver
      activeVoucherName
      activeVoucherCode
      discountPercentage
      discountAmount
      discountQty
    }
  }
`;
export const deleteUserInfo = /* GraphQL */ `
  mutation DeleteUserInfo(
    $input: DeleteUserInfoInput!
    $condition: ModelUserInfoConditionInput
  ) {
    deleteUserInfo(input: $input, condition: $condition) {
      id
      name
      surname
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      driver
      activeVoucherName
      activeVoucherCode
      discountPercentage
      discountAmount
      discountQty
    }
  }
`;
export const createCarData = /* GraphQL */ `
  mutation CreateCarData(
    $input: CreateCarDataInput!
    $condition: ModelCarDataConditionInput
  ) {
    createCarData(input: $input, condition: $condition) {
      id
      userID
      model
      make
      numberPlate
      colour
      createdAt
      updatedAt
    }
  }
`;
export const updateCarData = /* GraphQL */ `
  mutation UpdateCarData(
    $input: UpdateCarDataInput!
    $condition: ModelCarDataConditionInput
  ) {
    updateCarData(input: $input, condition: $condition) {
      id
      userID
      model
      make
      numberPlate
      colour
      createdAt
      updatedAt
    }
  }
`;
export const deleteCarData = /* GraphQL */ `
  mutation DeleteCarData(
    $input: DeleteCarDataInput!
    $condition: ModelCarDataConditionInput
  ) {
    deleteCarData(input: $input, condition: $condition) {
      id
      userID
      model
      make
      numberPlate
      colour
      createdAt
      updatedAt
    }
  }
`;
export const createCartProductFirst = /* GraphQL */ `
  mutation CreateCartProductFirst(
    $input: CreateCartProductFirstInput!
    $condition: ModelCartProductFirstConditionInput
  ) {
    createCartProductFirst(input: $input, condition: $condition) {
      id
      userSub
      quantity
      productID
      title
      price
      image
      brand
      cylinder
      category
      createdAt
      updatedAt
    }
  }
`;
export const updateCartProductFirst = /* GraphQL */ `
  mutation UpdateCartProductFirst(
    $input: UpdateCartProductFirstInput!
    $condition: ModelCartProductFirstConditionInput
  ) {
    updateCartProductFirst(input: $input, condition: $condition) {
      id
      userSub
      quantity
      productID
      title
      price
      image
      brand
      cylinder
      category
      createdAt
      updatedAt
    }
  }
`;
export const deleteCartProductFirst = /* GraphQL */ `
  mutation DeleteCartProductFirst(
    $input: DeleteCartProductFirstInput!
    $condition: ModelCartProductFirstConditionInput
  ) {
    deleteCartProductFirst(input: $input, condition: $condition) {
      id
      userSub
      quantity
      productID
      title
      price
      image
      brand
      cylinder
      category
      createdAt
      updatedAt
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      status
      statusB
      statusC
      lat
      lng
      country
      city
      amount
      qty
      driverID
      driverSub
      car
      model
      plate
      driverName
      driverSurname
      driverImage
      type
      steps
      delTime
      delDistance
      createdAt
      updatedAt
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      status
      statusB
      statusC
      lat
      lng
      country
      city
      amount
      qty
      driverID
      driverSub
      car
      model
      plate
      driverName
      driverSurname
      driverImage
      type
      steps
      delTime
      delDistance
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      status
      statusB
      statusC
      lat
      lng
      country
      city
      amount
      qty
      driverID
      driverSub
      car
      model
      plate
      driverName
      driverSurname
      driverImage
      type
      steps
      delTime
      delDistance
      createdAt
      updatedAt
    }
  }
`;
export const createOrderProduct = /* GraphQL */ `
  mutation CreateOrderProduct(
    $input: CreateOrderProductInput!
    $condition: ModelOrderProductConditionInput
  ) {
    createOrderProduct(input: $input, condition: $condition) {
      id
      quantity
      price
      image
      title
      brand
      titilet
      cylinder
      productID
      orderID
      createdAt
      updatedAt
    }
  }
`;
export const updateOrderProduct = /* GraphQL */ `
  mutation UpdateOrderProduct(
    $input: UpdateOrderProductInput!
    $condition: ModelOrderProductConditionInput
  ) {
    updateOrderProduct(input: $input, condition: $condition) {
      id
      quantity
      price
      image
      title
      brand
      titilet
      cylinder
      productID
      orderID
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrderProduct = /* GraphQL */ `
  mutation DeleteOrderProduct(
    $input: DeleteOrderProductInput!
    $condition: ModelOrderProductConditionInput
  ) {
    deleteOrderProduct(input: $input, condition: $condition) {
      id
      quantity
      price
      image
      title
      brand
      titilet
      cylinder
      productID
      orderID
      createdAt
      updatedAt
    }
  }
`;
export const createEmergencyPrices = /* GraphQL */ `
  mutation CreateEmergencyPrices(
    $input: CreateEmergencyPricesInput!
    $condition: ModelEmergencyPricesConditionInput
  ) {
    createEmergencyPrices(input: $input, condition: $condition) {
      id
      title
      image
      price
      available
      category
      createdAt
      updatedAt
    }
  }
`;
export const updateEmergencyPrices = /* GraphQL */ `
  mutation UpdateEmergencyPrices(
    $input: UpdateEmergencyPricesInput!
    $condition: ModelEmergencyPricesConditionInput
  ) {
    updateEmergencyPrices(input: $input, condition: $condition) {
      id
      title
      image
      price
      available
      category
      createdAt
      updatedAt
    }
  }
`;
export const deleteEmergencyPrices = /* GraphQL */ `
  mutation DeleteEmergencyPrices(
    $input: DeleteEmergencyPricesInput!
    $condition: ModelEmergencyPricesConditionInput
  ) {
    deleteEmergencyPrices(input: $input, condition: $condition) {
      id
      title
      image
      price
      available
      category
      createdAt
      updatedAt
    }
  }
`;
export const createMessageList = /* GraphQL */ `
  mutation CreateMessageList(
    $input: CreateMessageListInput!
    $condition: ModelMessageListConditionInput
  ) {
    createMessageList(input: $input, condition: $condition) {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const updateMessageList = /* GraphQL */ `
  mutation UpdateMessageList(
    $input: UpdateMessageListInput!
    $condition: ModelMessageListConditionInput
  ) {
    updateMessageList(input: $input, condition: $condition) {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const deleteMessageList = /* GraphQL */ `
  mutation DeleteMessageList(
    $input: DeleteMessageListInput!
    $condition: ModelMessageListConditionInput
  ) {
    deleteMessageList(input: $input, condition: $condition) {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const createChatRoom = /* GraphQL */ `
  mutation CreateChatRoom(
    $input: CreateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    createChatRoom(input: $input, condition: $condition) {
      id
      driverID
      userID
      started
      createdAt
      updatedAt
    }
  }
`;
export const updateChatRoom = /* GraphQL */ `
  mutation UpdateChatRoom(
    $input: UpdateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    updateChatRoom(input: $input, condition: $condition) {
      id
      driverID
      userID
      started
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatRoom = /* GraphQL */ `
  mutation DeleteChatRoom(
    $input: DeleteChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    deleteChatRoom(input: $input, condition: $condition) {
      id
      driverID
      userID
      started
      createdAt
      updatedAt
    }
  }
`;
export const createChatMessage = /* GraphQL */ `
  mutation CreateChatMessage(
    $input: CreateChatMessageInput!
    $condition: ModelChatMessageConditionInput
  ) {
    createChatMessage(input: $input, condition: $condition) {
      id
      chatRoomID
      message
      sender
      receiver
      createdAt
      updatedAt
    }
  }
`;
export const updateChatMessage = /* GraphQL */ `
  mutation UpdateChatMessage(
    $input: UpdateChatMessageInput!
    $condition: ModelChatMessageConditionInput
  ) {
    updateChatMessage(input: $input, condition: $condition) {
      id
      chatRoomID
      message
      sender
      receiver
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatMessage = /* GraphQL */ `
  mutation DeleteChatMessage(
    $input: DeleteChatMessageInput!
    $condition: ModelChatMessageConditionInput
  ) {
    deleteChatMessage(input: $input, condition: $condition) {
      id
      chatRoomID
      message
      sender
      receiver
      createdAt
      updatedAt
    }
  }
`;
export const createNotificationsList = /* GraphQL */ `
  mutation CreateNotificationsList(
    $input: CreateNotificationsListInput!
    $condition: ModelNotificationsListConditionInput
  ) {
    createNotificationsList(input: $input, condition: $condition) {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const updateNotificationsList = /* GraphQL */ `
  mutation UpdateNotificationsList(
    $input: UpdateNotificationsListInput!
    $condition: ModelNotificationsListConditionInput
  ) {
    updateNotificationsList(input: $input, condition: $condition) {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotificationsList = /* GraphQL */ `
  mutation DeleteNotificationsList(
    $input: DeleteNotificationsListInput!
    $condition: ModelNotificationsListConditionInput
  ) {
    deleteNotificationsList(input: $input, condition: $condition) {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const createCarList = /* GraphQL */ `
  mutation CreateCarList(
    $input: CreateCarListInput!
    $condition: ModelCarListConditionInput
  ) {
    createCarList(input: $input, condition: $condition) {
      id
      car
      name
      surname
      model
      plate
      brand
      createdAt
      updatedAt
    }
  }
`;
export const updateCarList = /* GraphQL */ `
  mutation UpdateCarList(
    $input: UpdateCarListInput!
    $condition: ModelCarListConditionInput
  ) {
    updateCarList(input: $input, condition: $condition) {
      id
      car
      name
      surname
      model
      plate
      brand
      createdAt
      updatedAt
    }
  }
`;
export const deleteCarList = /* GraphQL */ `
  mutation DeleteCarList(
    $input: DeleteCarListInput!
    $condition: ModelCarListConditionInput
  ) {
    deleteCarList(input: $input, condition: $condition) {
      id
      car
      name
      surname
      model
      plate
      brand
      createdAt
      updatedAt
    }
  }
`;
export const createDriverList = /* GraphQL */ `
  mutation CreateDriverList(
    $input: CreateDriverListInput!
    $condition: ModelDriverListConditionInput
  ) {
    createDriverList(input: $input, condition: $condition) {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const updateDriverList = /* GraphQL */ `
  mutation UpdateDriverList(
    $input: UpdateDriverListInput!
    $condition: ModelDriverListConditionInput
  ) {
    updateDriverList(input: $input, condition: $condition) {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const deleteDriverList = /* GraphQL */ `
  mutation DeleteDriverList(
    $input: DeleteDriverListInput!
    $condition: ModelDriverListConditionInput
  ) {
    deleteDriverList(input: $input, condition: $condition) {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const createVouchers = /* GraphQL */ `
  mutation CreateVouchers(
    $input: CreateVouchersInput!
    $condition: ModelVouchersConditionInput
  ) {
    createVouchers(input: $input, condition: $condition) {
      id
      name
      amount
      createdAt
      updatedAt
    }
  }
`;
export const updateVouchers = /* GraphQL */ `
  mutation UpdateVouchers(
    $input: UpdateVouchersInput!
    $condition: ModelVouchersConditionInput
  ) {
    updateVouchers(input: $input, condition: $condition) {
      id
      name
      amount
      createdAt
      updatedAt
    }
  }
`;
export const deleteVouchers = /* GraphQL */ `
  mutation DeleteVouchers(
    $input: DeleteVouchersInput!
    $condition: ModelVouchersConditionInput
  ) {
    deleteVouchers(input: $input, condition: $condition) {
      id
      name
      amount
      createdAt
      updatedAt
    }
  }
`;
export const createUsedVouchers = /* GraphQL */ `
  mutation CreateUsedVouchers(
    $input: CreateUsedVouchersInput!
    $condition: ModelUsedVouchersConditionInput
  ) {
    createUsedVouchers(input: $input, condition: $condition) {
      id
      userID
      voucherID
      createdAt
      updatedAt
    }
  }
`;
export const updateUsedVouchers = /* GraphQL */ `
  mutation UpdateUsedVouchers(
    $input: UpdateUsedVouchersInput!
    $condition: ModelUsedVouchersConditionInput
  ) {
    updateUsedVouchers(input: $input, condition: $condition) {
      id
      userID
      voucherID
      createdAt
      updatedAt
    }
  }
`;
export const deleteUsedVouchers = /* GraphQL */ `
  mutation DeleteUsedVouchers(
    $input: DeleteUsedVouchersInput!
    $condition: ModelUsedVouchersConditionInput
  ) {
    deleteUsedVouchers(input: $input, condition: $condition) {
      id
      userID
      voucherID
      createdAt
      updatedAt
    }
  }
`;
export const createBannerList = /* GraphQL */ `
  mutation CreateBannerList(
    $input: CreateBannerListInput!
    $condition: ModelBannerListConditionInput
  ) {
    createBannerList(input: $input, condition: $condition) {
      id
      title
      titletwo
      image
      description
      createdAt
      updatedAt
    }
  }
`;
export const updateBannerList = /* GraphQL */ `
  mutation UpdateBannerList(
    $input: UpdateBannerListInput!
    $condition: ModelBannerListConditionInput
  ) {
    updateBannerList(input: $input, condition: $condition) {
      id
      title
      titletwo
      image
      description
      createdAt
      updatedAt
    }
  }
`;
export const deleteBannerList = /* GraphQL */ `
  mutation DeleteBannerList(
    $input: DeleteBannerListInput!
    $condition: ModelBannerListConditionInput
  ) {
    deleteBannerList(input: $input, condition: $condition) {
      id
      title
      titletwo
      image
      description
      createdAt
      updatedAt
    }
  }
`;
export const createOrderReport = /* GraphQL */ `
  mutation CreateOrderReport(
    $input: CreateOrderReportInput!
    $condition: ModelOrderReportConditionInput
  ) {
    createOrderReport(input: $input, condition: $condition) {
      id
      title
      description
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateOrderReport = /* GraphQL */ `
  mutation UpdateOrderReport(
    $input: UpdateOrderReportInput!
    $condition: ModelOrderReportConditionInput
  ) {
    updateOrderReport(input: $input, condition: $condition) {
      id
      title
      description
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrderReport = /* GraphQL */ `
  mutation DeleteOrderReport(
    $input: DeleteOrderReportInput!
    $condition: ModelOrderReportConditionInput
  ) {
    deleteOrderReport(input: $input, condition: $condition) {
      id
      title
      description
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
