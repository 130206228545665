import { View, Text, FlatList, TouchableOpacity, Dimensions, TextInput } from 'react-native'
import React, {useState, useEffect} from 'react';
import { Ionicons, MaterialCommunityIcons, Octicons, AntDesign, Entypo, MaterialIcons, Feather  } from '@expo/vector-icons'; 
import { useNavigation, useRoute } from '@react-navigation/native';
import { ScrollView } from 'react-native';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { onAddedOrder, onUpdatedOrder, onDeletedOrder } from '../../graphql/subscriptions';


const {width, height} = Dimensions.get('window')

const OrdersScreen = () => {

    const navigation = useNavigation();

    const [OrderUpdt, setOrderUpdt] = useState([])
    const [orderList, setOrderList] = useState([])

    const [orderListB, setOrderListB] = useState([])
    const [orderActive, setOrderActive] = useState([])

    

     const itemSeperator = ()=> {
        return(
            <View style={{height: 1, backgroundColor: '#d1d1d1'}} />
        )
    }

    const renderList =({item})=>{
        return(
            <TouchableOpacity onPress={()=> navigation.navigate('TopActivity', {orderDet: item})} style={{height: 80, width: '100%', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between'}}>
                    
                    
                    <View style={{marginLeft: 30, width: 250}}>

                            <View style={{}}>
                                <Text style={{fontSize: 16, fontWeight: '800'}}>#{item?.id.slice(0,10)}...</Text>
                            </View>
                        <View style={{flexDirection: 'row', height: 30}}>

                            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                <Entypo name='shopping-cart' size={10} color="black" />
                                <View style={{height: 20, backgroundColor: 'red', borderRadius: 5, marginLeft: 5}}>
                                    <Text style={{marginLeft: 10, width: 80, fontSize: 13, color: 'white'}}>{item?.type || `Type`}</Text>  
                                </View>
                            </View>
                          
                            <View style={{flexDirection: 'row', marginLeft: 15, alignItems: 'center'}}>
                                {/* calendar */}
                                <View>
                                   <Entypo name="calendar" size={10} color="black" />
                                </View>

                                {/* date */}
                                <View style={{marginLeft: 10}}>
                                    <Text style={{fontSize: 13}}>{item?.createdAt.slice(0,10)}</Text>
                                </View>
                            </View>

                           

                        </View>
                        
                        <Text style={{fontSize: 13}}>P{Number(item?.orderAmount).toFixed(2)}</Text>

                         
                    </View>

                    <View style={{flexDirection: 'row'}}>
                        <TouchableOpacity onPress={()=> console.warn('cancelled')} style={{width: 20, height: 20, borderRadius: 50, backgroundColor: 'red', alignItems: 'center', justifyContent: 'center'}}>
                            <Entypo name="cross" size={12} color="white" />
                        </TouchableOpacity>

                        
                    </View>

                    <View style={{marginRight: 30}}>
                        <Ionicons name="md-arrow-forward-circle-sharp" size={24} color="black" />
                    </View>
            </TouchableOpacity>
        )
    }


     async function fetchUsers() {

        const allOrders = await API.graphql({ query: queries.listOrders, variables:
           { filter : {
             statusC: {eq: 'Pending'},
             statusB: {eq: 'Ongoing'},
           },

           }});


        const todos = allOrders.data.listOrders.items
        setOrderList(todos)

        // active orders only

        const activeOrders = await API.graphql({ query: queries.listOrders, variables:
           { 
            filter : {   
               and: [{  statusB: {eq: 'Ongoing'}},
                        {statusC:{ ne: 'Pending'}}]
        },

           }});



        const todosActive = activeOrders.data.listOrders.items
        setOrderActive(todosActive)


         const allOrdersList = await API.graphql({ query: queries.listOrders});

         const todoss = allOrdersList.data.listOrders.items

         setOrderListB(todoss)



     }

     useEffect(() => {
        fetchUsers();
     }, [OrderUpdt])
     





     const renderOrders =({item})=>{

        const showingContent = () => {
        if(item?.steps === null){
            return(
                <View>
                    <Text style={{fontSize: 12, color: 'white'}}>Accepted</Text>
                </View>
            )    
        } else if(item?.steps === 2){
             return(
                <View>
                  <Text style={{fontSize: 12, color: 'white'}}>Fetching</Text>
                </View>
             )   
        } else if(item?.steps === 3){
            return(
                <View>
                    <Text style={{fontSize: 12, color: 'white'}}>En-Route</Text>
                </View>
            )
        } else if(item?.steps === 4){
            return(
                <View>
                    <Text style={{fontSize: 12, color: 'white'}}>Arrived</Text>
                </View>
            )
        }
    }



        
        return(
            <TouchableOpacity onPress={()=> navigation.navigate('TopActivityCompleted', {orderDet: item})} style={{height: 80, width: '100%', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between'}}>
                    
                    
                    <View style={{marginLeft: 30, width: 250}}>

                            <View style={{}}>
                                <Text style={{fontSize: 16, fontWeight: '800'}}>#{item?.id.slice(0,10)}</Text>
                            </View>
                        <View style={{flexDirection: 'row', height: 30}}>

                            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                <Entypo name='shopping-cart' size={10} color="black" />
                                <Text style={{marginLeft: 10, width: 80, fontSize: 12}}>{item?.type || `Type`}</Text>  
                            </View>
                          
                            <View style={{flexDirection: 'row', marginLeft: 15, alignItems: 'center'}}>
                                {/* calendar */}
                                <View>
                                   <Entypo name="calendar" size={10} color="black" />
                                </View>

                                {/* date */}
                                <View style={{marginLeft: 10}}>
                                    <Text style={{fontSize: 12}}>{item?.createdAt.slice(0,10)}</Text>
                                </View>
                            </View>

                           

                        </View>
                        
                        <Text style={{fontSize: 12}}>P{Number(item?.orderAmount).toFixed(2)}</Text>

                         
                    </View>

                    <View style={{flexDirection: 'row'}}>
                        <View style={{width: 80, height: 30, borderRadius: 10, backgroundColor: 'orange', alignItems: 'center', justifyContent: 'center'}}>
                            {/* <Text style={{fontSize: 12, color: 'white', fontWeight: '500'}}>{item?.statusC || `err`}</Text> */}
                            {showingContent()}
                        </View>

                        
                    </View>

                    <View style={{marginRight: 30}}>
                        <Ionicons name="md-arrow-forward-circle-sharp" size={24} color="black" />
                    </View>

            </TouchableOpacity>
        )
    }

    const renderActiveOrders =({item})=>{

        const showingContent = () => {
        if(item?.steps === null){
            return(
                <View>
                    <Text style={{fontSize: 12, color: 'white'}}>Accepted</Text>
                </View>
            )    
        } else if(item?.steps === 2){
             return(
                <View>
                  <Text style={{fontSize: 12, color: 'white'}}>Fetching</Text>
                </View>
             )   
        } else if(item?.steps === 3){
            return(
                <View>
                    <Text style={{fontSize: 12, color: 'white'}}>En-Route</Text>
                </View>
            )
        } else if(item?.steps === 4){
            return(
                <View>
                    <Text style={{fontSize: 12, color: 'white'}}>Arrived</Text>
                </View>
            )
        }
    }



        
        return(
            <TouchableOpacity onPress={()=> navigation.navigate('TopActivityCompleted', {orderDet: item})} style={{height: 80, width: '100%', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between'}}>
                    
                    
                    <View style={{marginLeft: 30, width: 250}}>

                            <View style={{}}>
                                <Text style={{fontSize: 16, fontWeight: '800'}}>#{item?.id.slice(0,10)}</Text>
                            </View>
                        <View style={{flexDirection: 'row', height: 30}}>

                            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                <Entypo name='shopping-cart' size={10} color="black" />
                                <Text style={{marginLeft: 10, width: 80, fontSize: 12}}>{item?.type || `Type`}</Text>  
                            </View>
                          
                            <View style={{flexDirection: 'row', marginLeft: 15, alignItems: 'center'}}>
                                {/* calendar */}
                                <View>
                                   <Entypo name="calendar" size={10} color="black" />
                                </View>

                                {/* date */}
                                <View style={{marginLeft: 10}}>
                                    <Text style={{fontSize: 12}}>{item?.createdAt.slice(0,10)}</Text>
                                </View>
                            </View>

                           

                        </View>
                        
                        <Text style={{fontSize: 12}}>P{Number(item?.orderAmount).toFixed(2)}</Text>

                         
                    </View>

                    <View style={{flexDirection: 'row'}}>
                        <View style={{width: 80, height: 30, borderRadius: 10, backgroundColor: 'orange', alignItems: 'center', justifyContent: 'center'}}>
                            {/* <Text style={{fontSize: 12, color: 'white', fontWeight: '500'}}>{item?.statusC || `err`}</Text> */}
                            {showingContent()}
                        </View>

                        
                    </View>

                    <View style={{marginRight: 30}}>
                        <Ionicons name="md-arrow-forward-circle-sharp" size={24} color="black" />
                    </View>

            </TouchableOpacity>
        )
    }


    let subsUpdate;
    let subsUpdateDel;
    let subsUpdateCreate;

    function setUpSus(){
   

     subsUpdate = API.graphql(graphqlOperation(onUpdatedOrder)).subscribe( {next: (daraa) => {
         setOrderUpdt(daraa)
     }, })
     
     subsUpdateDel = API.graphql(graphqlOperation(onDeletedOrder)).subscribe( {next: (daraaa) => {
         setOrderUpdt(daraaa)
     }, }) 

     subsUpdateCreate = API.graphql(graphqlOperation(onAddedOrder)).subscribe( {next: (daraaaaa) => {
         setOrderUpdt(daraaaaa)
     }, }) 

    }

    useEffect(() =>{
        setUpSus();

        return() =>{
            subsUpdate.unsubscribe();
            subsUpdateDel.unsubscribe();
            subsUpdateCreate.unsubscribe();
        };

    },[]);


    orderActive.sort(function(a, b) {

        const newerDate = String(a.createdAt).replace(/\D/g, "")
        const newerB = String(b.createdAt).replace(/\D/g, "")

    return parseFloat(newerB) - parseFloat(newerDate);

    })
    
    orderListB.sort(function(a, b) {

        const newerDate = String(a.createdAt).replace(/\D/g, "")
        const newerB = String(b.createdAt).replace(/\D/g, "")

    return parseFloat(newerB) - parseFloat(newerDate);

    })

    

 orderList.sort(function(a, b) {

        const newerDate = String(a.createdAt).replace(/\D/g, "")
        const newerB = String(b.createdAt).replace(/\D/g, "")

    return parseFloat(newerB) - parseFloat(newerDate);

    })

  return (
    <View style={{flex: 1, backgroundColor: 'white', flexDirection: 'row'}}>
      
      <View style={{width: '33%', borderRightWidth: 1, borderColor: '#d1d1d1'}}>
        <View style={{marginLeft: 25, marginTop: 25}}>
            <Text style={{fontSize: 18, fontWeight: '800'}}>Order Requests</Text>
        </View>

        <View style={{marginTop: 40}}>
            <FlatList 
                showsVerticalScrollIndicator={true}
                scrollEnabled={true}
                data={orderList}
                keyExtractor={(item, index) => index.toString()}
                renderItem={renderList}
                style={{height: 400}}
                ItemSeparatorComponent={itemSeperator}
            />
      
        </View>

      </View>

       <View style={{width: '33%', height: '100%'}}>
            <View style={{marginLeft: 25, marginTop: 25, flexDirection: 'row', alignItems: 'center'}}>
                    <Text style={{fontSize: 18, fontWeight: '800'}}>Active Order(s)</Text>


                    <View style={{margin: 0, marginTop: 15, marginLeft: 15, flexDirection: 'row', borderWidth: 0, borderColor: 'black', borderRadius: 20, justifyContent: 'center'}}>

                        <View style={{borderWidth: 1, borderColor: 'gray', borderRadius: 20, width: 300, height: 40, flexDirection: 'row', alignItems: 'center'}}>
                            <View style={{height: 30, width: 30, marginLeft: 5, alignItems: 'center', justifyContent: 'center', borderRadius: 50, backgroundColor: '#d1d1d1'}}>
                                <Feather name="search" size={24} color="black" />
                            </View>

                            <View style={{marginLeft: 10}}>
                                <TextInput placeholder='Order Number' style={{width: 250}} />
                            </View>
                        </View>
                        

                    </View>


            </View>

            <View style={{marginTop: 20}}>
                <FlatList 
                    showsVerticalScrollIndicator={true}
                    scrollEnabled={true}
                    data={orderActive}
                    keyExtractor={(item, index) => index.toString()}
                    renderItem={renderActiveOrders}
                    style={{height: 380}}
                    ItemSeparatorComponent={itemSeperator}
                />
            </View>
      </View>

      <View style={{width: '33%', height: '100%'}}>
            <View style={{marginLeft: 25, marginTop: 25, flexDirection: 'row', alignItems: 'center'}}>
                    <Text style={{fontSize: 18, fontWeight: '800'}}>Order(s)</Text>


                    <View style={{margin: 0, marginTop: 15, marginLeft: 15, flexDirection: 'row', borderWidth: 0, borderColor: 'black', borderRadius: 20, justifyContent: 'center'}}>

                        <View style={{borderWidth: 1, borderColor: 'gray', borderRadius: 20, width: 300, height: 40, flexDirection: 'row', alignItems: 'center'}}>
                            <View style={{height: 30, width: 30, marginLeft: 5, alignItems: 'center', justifyContent: 'center', borderRadius: 50, backgroundColor: '#d1d1d1'}}>
                                <Feather name="search" size={24} color="black" />
                            </View>

                            <View style={{marginLeft: 10}}>
                                <TextInput placeholder='Order Number' style={{width: 250}} />
                            </View>
                        </View>
                        

                    </View>


            </View>

            <View style={{marginTop: 20}}>
                <FlatList 
                    showsVerticalScrollIndicator={true}
                    scrollEnabled={true}
                    data={orderListB}
                    keyExtractor={(item, index) => index.toString()}
                    renderItem={renderOrders}
                    style={{height: 380}}
                    ItemSeparatorComponent={itemSeperator}
                />
            </View>
      </View>
    </View>
  )
}

export default OrdersScreen