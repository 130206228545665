import { StyleSheet, Text, View, Dimensions, TouchableOpacity, FlatList, Image } from 'react-native'
import React, {useState, useEffect, useRef} from 'react'
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { async } from '@firebase/util';
import { API, graphqlOperation, Auth } from 'aws-amplify';
const {width, height} = Dimensions.get('screen')


const NewChatList = () => {


    const messagesList = [
        {
            name: 'Siyabonga Dlamini',
            orderID: 33134322345123,
            read: false,
            createdAt: 100,
            updatedAt: 100
        },
        {
            name: 'Zolani Nkosi',
            orderID: 355334245556,
            read: false,
            createdAt: 120,
            updatedAt: 120
        },
        {
            name: 'Andile Dikana',
            orderID: 987655678,
            read: true,
            createdAt: 130,
            updatedAt: 130
        },
        {
            name: 'Sbane Dube',
            orderID: 7746483393,
            read: false,
            createdAt: 140,
            updatedAt: 140
        },

    ]

    const [messOwner, setMessOwner] = useState([])


    const fetchMessageList = async() =>{
        const allMessOwners = await API.graphql({ query: queries.listChatRooms, variables: 
        {
            filter :   { started: 
                        {eq: 
                            true
                        },
                        }
        } });

        const messData = allMessOwners.data.listChatRooms.items
        setMessOwner(messData)
    }

    useEffect(() => {
        fetchMessageList()
    }, [])
    

    const renderMessages = ({item}) =>{

        const readornot = ()=>{
            if(item?.read === false){
                return(
                    <View style={{height: 10, width:10, borderRadius: 20, backgroundColor: 'blue'}}/>
                )
            }
        }


        return(
            <View>
                <TouchableOpacity style={{flexDirection: 'row'}}>
                    <View>
                        <Image style={{width: 60, height: 60, resizeMode: 'contain'}} source={require('../../../assets/casual-life-3d-profile-picture-of-person-in-glasses-and-orange-shirt.png')}/>
                    </View>

                    <View style={{height: '100%', width: width / 4, justifyContent: 'center', marginLeft: 25}}>
                        <Text>{item?.name}</Text>
                        <Text style={{fontSize: 13, fontStyle: 'italic'}}>{item?.updatedAt}</Text>
                    </View>

                    <View style={{height: '100%', alignItems: 'center'}}>
                        {readornot()}
                    </View>
                </TouchableOpacity>
            </View>
        )
    }

    const renderSeperator = () =>{
        return(
            <View style={{height: 1, width: width, marginTop: 10, marginBottom: 10, backgroundColor: '#d1d1d1'}}/>
        )
    }

    
  return (
    <View style={{flex: 1, backgroundColor: 'white'}}> 
        <View style={{marginLeft: 25, marginTop: 25}}>
            <Text style={{fontSize: 18, fontWeight: '800'}}>Order Chat</Text>
        </View>

        <View style={{margin: 25}}>
            <FlatList 
                data={messagesList}
                renderItem={renderMessages}
                ItemSeparatorComponent={renderSeperator}
            />
        </View>
    </View>
  )
}

export default NewChatList

const styles = StyleSheet.create({})