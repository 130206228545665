/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCarDataAdded = /* GraphQL */ `
  subscription OnCarDataAdded {
    onCarDataAdded {
      id
      userID
      model
      make
      numberPlate
      colour
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatedCarDataAdded = /* GraphQL */ `
  subscription OnUpdatedCarDataAdded {
    onUpdatedCarDataAdded {
      id
      userID
      model
      make
      numberPlate
      colour
      createdAt
      updatedAt
    }
  }
`;
export const onDeletedCarDataAdded = /* GraphQL */ `
  subscription OnDeletedCarDataAdded {
    onDeletedCarDataAdded {
      id
      userID
      model
      make
      numberPlate
      colour
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatedUserDataAdded = /* GraphQL */ `
  subscription OnUpdatedUserDataAdded {
    onUpdatedUserDataAdded {
      id
      name
      surname
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      driver
      activeVoucherName
      activeVoucherCode
      discountPercentage
      discountAmount
      discountQty
    }
  }
`;
export const onUpdatedOrder = /* GraphQL */ `
  subscription OnUpdatedOrder {
    onUpdatedOrder {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      status
      statusB
      statusC
      lat
      lng
      country
      city
      amount
      qty
      driverID
      driverSub
      car
      model
      plate
      driverName
      driverSurname
      driverImage
      type
      steps
      delTime
      delDistance
      createdAt
      updatedAt
    }
  }
`;
export const onAddedOrder = /* GraphQL */ `
  subscription OnAddedOrder {
    onAddedOrder {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      status
      statusB
      statusC
      lat
      lng
      country
      city
      amount
      qty
      driverID
      driverSub
      car
      model
      plate
      driverName
      driverSurname
      driverImage
      type
      steps
      delTime
      delDistance
      createdAt
      updatedAt
    }
  }
`;
export const onDeletedOrder = /* GraphQL */ `
  subscription OnDeletedOrder {
    onDeletedOrder {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      status
      statusB
      statusC
      lat
      lng
      country
      city
      amount
      qty
      driverID
      driverSub
      car
      model
      plate
      driverName
      driverSurname
      driverImage
      type
      steps
      delTime
      delDistance
      createdAt
      updatedAt
    }
  }
`;
export const onAddedDriver = /* GraphQL */ `
  subscription OnAddedDriver {
    onAddedDriver {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatedDriver = /* GraphQL */ `
  subscription OnUpdatedDriver {
    onUpdatedDriver {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const onDeletedDriver = /* GraphQL */ `
  subscription OnDeletedDriver {
    onDeletedDriver {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const onAddedVouchers = /* GraphQL */ `
  subscription OnAddedVouchers {
    onAddedVouchers {
      id
      name
      amount
      createdAt
      updatedAt
    }
  }
`;
export const onDeletedVouchers = /* GraphQL */ `
  subscription OnDeletedVouchers {
    onDeletedVouchers {
      id
      name
      amount
      createdAt
      updatedAt
    }
  }
`;
export const onAddedUsedVouchers = /* GraphQL */ `
  subscription OnAddedUsedVouchers {
    onAddedUsedVouchers {
      id
      userID
      voucherID
      createdAt
      updatedAt
    }
  }
`;
export const onDeletedUsedVouchers = /* GraphQL */ `
  subscription OnDeletedUsedVouchers {
    onDeletedUsedVouchers {
      id
      userID
      voucherID
      createdAt
      updatedAt
    }
  }
`;
export const onProductsFirstAdded = /* GraphQL */ `
  subscription OnProductsFirstAdded {
    onProductsFirstAdded {
      id
      title
      brand
      price
      image
      description
      category
      cylinder
      available
      createdAt
      updatedAt
    }
  }
`;
export const onProductsFirstUpdated = /* GraphQL */ `
  subscription OnProductsFirstUpdated {
    onProductsFirstUpdated {
      id
      title
      brand
      price
      image
      description
      category
      cylinder
      available
      createdAt
      updatedAt
    }
  }
`;
export const onProductsFirstDeleted = /* GraphQL */ `
  subscription OnProductsFirstDeleted {
    onProductsFirstDeleted {
      id
      title
      brand
      price
      image
      description
      category
      cylinder
      available
      createdAt
      updatedAt
    }
  }
`;
export const onEmergencyPricesAdded = /* GraphQL */ `
  subscription OnEmergencyPricesAdded {
    onEmergencyPricesAdded {
      id
      title
      image
      price
      available
      category
      createdAt
      updatedAt
    }
  }
`;
export const onEmergencyPricesUpdated = /* GraphQL */ `
  subscription OnEmergencyPricesUpdated {
    onEmergencyPricesUpdated {
      id
      title
      image
      price
      available
      category
      createdAt
      updatedAt
    }
  }
`;
export const onEmergencyPricesDeleted = /* GraphQL */ `
  subscription OnEmergencyPricesDeleted {
    onEmergencyPricesDeleted {
      id
      title
      image
      price
      available
      category
      createdAt
      updatedAt
    }
  }
`;
export const onCartProductUpdatedd = /* GraphQL */ `
  subscription OnCartProductUpdatedd {
    onCartProductUpdatedd {
      id
      userSub
      quantity
      productID
      title
      price
      image
      brand
      cylinder
      category
      createdAt
      updatedAt
    }
  }
`;
export const onCartProductDeleted = /* GraphQL */ `
  subscription OnCartProductDeleted {
    onCartProductDeleted {
      id
      userSub
      quantity
      productID
      title
      price
      image
      brand
      cylinder
      category
      createdAt
      updatedAt
    }
  }
`;
export const onCartProductAdded = /* GraphQL */ `
  subscription OnCartProductAdded {
    onCartProductAdded {
      id
      userSub
      quantity
      productID
      title
      price
      image
      brand
      cylinder
      category
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatedNotifications = /* GraphQL */ `
  subscription OnUpdatedNotifications {
    onUpdatedNotifications {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onCreatedNotifications = /* GraphQL */ `
  subscription OnCreatedNotifications {
    onCreatedNotifications {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onDeletedNotifications = /* GraphQL */ `
  subscription OnDeletedNotifications {
    onDeletedNotifications {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatedMessages = /* GraphQL */ `
  subscription OnUpdatedMessages {
    onUpdatedMessages {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onCreatedMessages = /* GraphQL */ `
  subscription OnCreatedMessages {
    onCreatedMessages {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onDeletedMessages = /* GraphQL */ `
  subscription OnDeletedMessages {
    onDeletedMessages {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onCreatedChatRoom = /* GraphQL */ `
  subscription OnCreatedChatRoom {
    onCreatedChatRoom {
      id
      driverID
      userID
      started
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatedChatRoom = /* GraphQL */ `
  subscription OnUpdatedChatRoom {
    onUpdatedChatRoom {
      id
      driverID
      userID
      started
      createdAt
      updatedAt
    }
  }
`;
export const onDeletedChatRoom = /* GraphQL */ `
  subscription OnDeletedChatRoom {
    onDeletedChatRoom {
      id
      driverID
      userID
      started
      createdAt
      updatedAt
    }
  }
`;
export const onCreatedChatMessage = /* GraphQL */ `
  subscription OnCreatedChatMessage {
    onCreatedChatMessage {
      id
      chatRoomID
      message
      sender
      receiver
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatedChatMessage = /* GraphQL */ `
  subscription OnUpdatedChatMessage {
    onUpdatedChatMessage {
      id
      chatRoomID
      message
      sender
      receiver
      createdAt
      updatedAt
    }
  }
`;
export const onDeletedChatMessage = /* GraphQL */ `
  subscription OnDeletedChatMessage {
    onDeletedChatMessage {
      id
      chatRoomID
      message
      sender
      receiver
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTodo = /* GraphQL */ `
  subscription OnCreateTodo {
    onCreateTodo {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTodo = /* GraphQL */ `
  subscription OnUpdateTodo {
    onUpdateTodo {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTodo = /* GraphQL */ `
  subscription OnDeleteTodo {
    onDeleteTodo {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProductsFirst = /* GraphQL */ `
  subscription OnCreateProductsFirst {
    onCreateProductsFirst {
      id
      title
      brand
      price
      image
      description
      category
      cylinder
      available
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProductsFirst = /* GraphQL */ `
  subscription OnUpdateProductsFirst {
    onUpdateProductsFirst {
      id
      title
      brand
      price
      image
      description
      category
      cylinder
      available
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProductsFirst = /* GraphQL */ `
  subscription OnDeleteProductsFirst {
    onDeleteProductsFirst {
      id
      title
      brand
      price
      image
      description
      category
      cylinder
      available
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserInfo = /* GraphQL */ `
  subscription OnCreateUserInfo {
    onCreateUserInfo {
      id
      name
      surname
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      driver
      activeVoucherName
      activeVoucherCode
      discountPercentage
      discountAmount
      discountQty
    }
  }
`;
export const onUpdateUserInfo = /* GraphQL */ `
  subscription OnUpdateUserInfo {
    onUpdateUserInfo {
      id
      name
      surname
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      driver
      activeVoucherName
      activeVoucherCode
      discountPercentage
      discountAmount
      discountQty
    }
  }
`;
export const onDeleteUserInfo = /* GraphQL */ `
  subscription OnDeleteUserInfo {
    onDeleteUserInfo {
      id
      name
      surname
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      driver
      activeVoucherName
      activeVoucherCode
      discountPercentage
      discountAmount
      discountQty
    }
  }
`;
export const onCreateCarData = /* GraphQL */ `
  subscription OnCreateCarData {
    onCreateCarData {
      id
      userID
      model
      make
      numberPlate
      colour
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCarData = /* GraphQL */ `
  subscription OnUpdateCarData {
    onUpdateCarData {
      id
      userID
      model
      make
      numberPlate
      colour
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCarData = /* GraphQL */ `
  subscription OnDeleteCarData {
    onDeleteCarData {
      id
      userID
      model
      make
      numberPlate
      colour
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCartProductFirst = /* GraphQL */ `
  subscription OnCreateCartProductFirst {
    onCreateCartProductFirst {
      id
      userSub
      quantity
      productID
      title
      price
      image
      brand
      cylinder
      category
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCartProductFirst = /* GraphQL */ `
  subscription OnUpdateCartProductFirst {
    onUpdateCartProductFirst {
      id
      userSub
      quantity
      productID
      title
      price
      image
      brand
      cylinder
      category
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCartProductFirst = /* GraphQL */ `
  subscription OnDeleteCartProductFirst {
    onDeleteCartProductFirst {
      id
      userSub
      quantity
      productID
      title
      price
      image
      brand
      cylinder
      category
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOrder = /* GraphQL */ `
  subscription OnCreateOrder {
    onCreateOrder {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      status
      statusB
      statusC
      lat
      lng
      country
      city
      amount
      qty
      driverID
      driverSub
      car
      model
      plate
      driverName
      driverSurname
      driverImage
      type
      steps
      delTime
      delDistance
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrder = /* GraphQL */ `
  subscription OnUpdateOrder {
    onUpdateOrder {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      status
      statusB
      statusC
      lat
      lng
      country
      city
      amount
      qty
      driverID
      driverSub
      car
      model
      plate
      driverName
      driverSurname
      driverImage
      type
      steps
      delTime
      delDistance
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrder = /* GraphQL */ `
  subscription OnDeleteOrder {
    onDeleteOrder {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      status
      statusB
      statusC
      lat
      lng
      country
      city
      amount
      qty
      driverID
      driverSub
      car
      model
      plate
      driverName
      driverSurname
      driverImage
      type
      steps
      delTime
      delDistance
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOrderProduct = /* GraphQL */ `
  subscription OnCreateOrderProduct {
    onCreateOrderProduct {
      id
      quantity
      price
      image
      title
      brand
      titilet
      cylinder
      productID
      orderID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrderProduct = /* GraphQL */ `
  subscription OnUpdateOrderProduct {
    onUpdateOrderProduct {
      id
      quantity
      price
      image
      title
      brand
      titilet
      cylinder
      productID
      orderID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrderProduct = /* GraphQL */ `
  subscription OnDeleteOrderProduct {
    onDeleteOrderProduct {
      id
      quantity
      price
      image
      title
      brand
      titilet
      cylinder
      productID
      orderID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEmergencyPrices = /* GraphQL */ `
  subscription OnCreateEmergencyPrices {
    onCreateEmergencyPrices {
      id
      title
      image
      price
      available
      category
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEmergencyPrices = /* GraphQL */ `
  subscription OnUpdateEmergencyPrices {
    onUpdateEmergencyPrices {
      id
      title
      image
      price
      available
      category
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEmergencyPrices = /* GraphQL */ `
  subscription OnDeleteEmergencyPrices {
    onDeleteEmergencyPrices {
      id
      title
      image
      price
      available
      category
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMessageList = /* GraphQL */ `
  subscription OnCreateMessageList {
    onCreateMessageList {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMessageList = /* GraphQL */ `
  subscription OnUpdateMessageList {
    onUpdateMessageList {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMessageList = /* GraphQL */ `
  subscription OnDeleteMessageList {
    onDeleteMessageList {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatRoom = /* GraphQL */ `
  subscription OnCreateChatRoom {
    onCreateChatRoom {
      id
      driverID
      userID
      started
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatRoom = /* GraphQL */ `
  subscription OnUpdateChatRoom {
    onUpdateChatRoom {
      id
      driverID
      userID
      started
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatRoom = /* GraphQL */ `
  subscription OnDeleteChatRoom {
    onDeleteChatRoom {
      id
      driverID
      userID
      started
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatMessage = /* GraphQL */ `
  subscription OnCreateChatMessage {
    onCreateChatMessage {
      id
      chatRoomID
      message
      sender
      receiver
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChatMessage = /* GraphQL */ `
  subscription OnUpdateChatMessage {
    onUpdateChatMessage {
      id
      chatRoomID
      message
      sender
      receiver
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteChatMessage = /* GraphQL */ `
  subscription OnDeleteChatMessage {
    onDeleteChatMessage {
      id
      chatRoomID
      message
      sender
      receiver
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNotificationsList = /* GraphQL */ `
  subscription OnCreateNotificationsList {
    onCreateNotificationsList {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotificationsList = /* GraphQL */ `
  subscription OnUpdateNotificationsList {
    onUpdateNotificationsList {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotificationsList = /* GraphQL */ `
  subscription OnDeleteNotificationsList {
    onDeleteNotificationsList {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCarList = /* GraphQL */ `
  subscription OnCreateCarList {
    onCreateCarList {
      id
      car
      name
      surname
      model
      plate
      brand
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCarList = /* GraphQL */ `
  subscription OnUpdateCarList {
    onUpdateCarList {
      id
      car
      name
      surname
      model
      plate
      brand
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCarList = /* GraphQL */ `
  subscription OnDeleteCarList {
    onDeleteCarList {
      id
      car
      name
      surname
      model
      plate
      brand
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDriverList = /* GraphQL */ `
  subscription OnCreateDriverList {
    onCreateDriverList {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDriverList = /* GraphQL */ `
  subscription OnUpdateDriverList {
    onUpdateDriverList {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDriverList = /* GraphQL */ `
  subscription OnDeleteDriverList {
    onDeleteDriverList {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const onCreateVouchers = /* GraphQL */ `
  subscription OnCreateVouchers {
    onCreateVouchers {
      id
      name
      amount
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateVouchers = /* GraphQL */ `
  subscription OnUpdateVouchers {
    onUpdateVouchers {
      id
      name
      amount
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteVouchers = /* GraphQL */ `
  subscription OnDeleteVouchers {
    onDeleteVouchers {
      id
      name
      amount
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUsedVouchers = /* GraphQL */ `
  subscription OnCreateUsedVouchers {
    onCreateUsedVouchers {
      id
      userID
      voucherID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUsedVouchers = /* GraphQL */ `
  subscription OnUpdateUsedVouchers {
    onUpdateUsedVouchers {
      id
      userID
      voucherID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUsedVouchers = /* GraphQL */ `
  subscription OnDeleteUsedVouchers {
    onDeleteUsedVouchers {
      id
      userID
      voucherID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBannerList = /* GraphQL */ `
  subscription OnCreateBannerList {
    onCreateBannerList {
      id
      title
      titletwo
      image
      description
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBannerList = /* GraphQL */ `
  subscription OnUpdateBannerList {
    onUpdateBannerList {
      id
      title
      titletwo
      image
      description
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBannerList = /* GraphQL */ `
  subscription OnDeleteBannerList {
    onDeleteBannerList {
      id
      title
      titletwo
      image
      description
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOrderReport = /* GraphQL */ `
  subscription OnCreateOrderReport {
    onCreateOrderReport {
      id
      title
      description
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOrderReport = /* GraphQL */ `
  subscription OnUpdateOrderReport {
    onUpdateOrderReport {
      id
      title
      description
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOrderReport = /* GraphQL */ `
  subscription OnDeleteOrderReport {
    onDeleteOrderReport {
      id
      title
      description
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
