import { StyleSheet, Text, View, TouchableOpacity, Dimensions, FlatList, Image } from 'react-native'
import React, {useState, useEffect} from 'react'
import { useNavigation } from '@react-navigation/native'
import { Ionicons, MaterialCommunityIcons, Octicons, AntDesign, Entypo, MaterialIcons  } from '@expo/vector-icons'; 
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../../../src/graphql/queries';
import * as mutations from '../../../src/graphql/mutations';
import { onAddedVouchers, onDeletedVouchers } from '../../graphql/subscriptions';

const {width, height} = Dimensions.get('screen')
const VoucherMain = () => {


    const navigation = useNavigation();
    const mainColour = '#FF4F00';

    const [voucherList, setVoucherList] = useState([]);
    const [updVoucher, setUpdVoucher] = useState([]);

   





    async function vouchers() {

        const allTodos = await API.graphql({ query: queries.listVouchers});

        const todos = allTodos.data.listVouchers.items
        setVoucherList(todos)


        console.log('items :', todos)

     }

     useEffect(() => {
       vouchers();
     }, [updVoucher])
     
    const renderBanners = ({item})=> {


        const deleteVoucher = async()=>{
            
            const deleteInput = {
                id: item?.id
            }

            await API.graphql({query: mutations.deleteVouchers, variables: {input: deleteInput}}).then((e)=> {
                    console.warn('deleted')
                
            })

        }   

        return(
            <View  style={{marginLeft: 15, height: 150, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <Image style={{width: 80, height: 80, borderRadius: 10}} source={require('../../../assets/attach.png')}/>
                     <View style={{marginLeft: 25, width: width /2}}>
                        <Text>{item?.name}</Text>
                        <Text style={{fontSize: 12}}>{item?.id}</Text>
                        <Text style={{fontSize: 12}}>P{Number(item?.amount).toFixed(2)}</Text>
                        <Text style={{fontSize: 12}}>{item?.createdAt.slice(0,10)}</Text>
                    </View>

                    <TouchableOpacity onPress={()=> deleteVoucher()} style={{width: 70, height: 40, marginLeft: 10, borderRadius: 10, backgroundColor: mainColour, alignItems: 'center', justifyContent: 'center'}}>
                        <Text style={{color: 'white', fontSize: 12}}>Delete</Text>
                    </TouchableOpacity>
                </View>
            </View>
        )

    }

    let subsUpdate;
    let subsUpdateDel;
    let subsUpdateCreate;

    function setUpSus(){

        subsUpdateCreate = API.graphql(graphqlOperation(onAddedVouchers)).subscribe( {next: (daraa) => {
         setUpdVoucher(daraa)
     }, })

     subsUpdateDel = API.graphql(graphqlOperation(onDeletedVouchers)).subscribe( {next: (daraaa) => {
         setUpdVoucher(daraaa)
     }, }) 

    }

    useEffect(() => {
      setUpSus()

      return() =>{
            subsUpdateDel.unsubscribe();
            subsUpdateCreate.unsubscribe();
        };
    }, [])
    




  return (
    <View style={{flex: 1, backgroundColor: 'white'}}>
      <View style={{marginTop: 15, borderBottomWidth: 1, width: '100%', borderColor: '#d1d1d1', height: 45}}>
            <TouchableOpacity style={{marginLeft: 15}} onPress={()=> navigation.goBack()}>
                <Ionicons name="arrow-back-outline" size={24} color="black" />
            </TouchableOpacity>
        </View>

        <View style={{margin: 15}}>
               <TouchableOpacity onPress={()=> navigation.navigate('AddVoucher')} style={{width: 120, height: 40, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderRadius: 5, backgroundColor: mainColour}}>
                    <Ionicons name="add-circle-sharp" size={24} color="white" />
                    <Text style={{color: 'white', fontSize: 12, marginLeft: 5}}>Add Voucher</Text> 
                </TouchableOpacity>
          </View> 

        <View style={{margin: 15}}>
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>Current Active Vouchers</Text>

            <FlatList 
                data={voucherList}
                renderItem={renderBanners}    
                style={{height: height/2.5}}
            />
        </View>




    </View>
  )
}

export default VoucherMain

const styles = StyleSheet.create({})