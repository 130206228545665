import React, {useState} from 'react'
import { createStackNavigator} from '@react-navigation/stack';
import { View } from 'react-native';
import UsersScreen from '../Screens/UsersScreen';
import UserDetails from '../Screens/UsersScreen/UserDetails';
import TopActivity from '../Screens/UsersScreen/TopActivity';
import StockMain from '../Screens/StockScreen.js/StockMain';
import DriversScreen from '../Screens/DriversSreen/DriversScreen';
import EditDriver from  '../Screens/DriversSreen/EditDriver';
import NewDriver from '../Screens/DriversSreen/NewDriver';
import AddDriver from '../Screens/DriversSreen/AddDriver';
import TopActivityCompleted from '../Screens/OrdersScreen/TopActivityCompleted';


const Stack = createStackNavigator();

const DriversStack = () => {

    return (
        <View style={{flex: 1}}>
            <Stack.Navigator
                screenOptions={{
                    headerShown: false
                    // header: () => <HeaderComponent searchValue={searchValue} setSearchValue={setSearchValue}/>
                }}
            >

                <Stack.Screen 
                
                name="Main">
                    {() => <DriversScreen/>}
                </Stack.Screen>
                <Stack.Screen component={EditDriver} name="Edit" options={{headerShown: false}} />
                <Stack.Screen component={NewDriver} name="NewDriver" options={{headerShown: false}} /> 
                <Stack.Screen component={AddDriver} name="AddDriver" options={{headerShown: false}} /> 
            </Stack.Navigator>
        </View>
    );

};

export default DriversStack;