/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      id
      name
      description
      createdAt
      updatedAt
    }
  }
`;
export const listTodos = /* GraphQL */ `
  query ListTodos(
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProductsFirst = /* GraphQL */ `
  query GetProductsFirst($id: ID!) {
    getProductsFirst(id: $id) {
      id
      title
      brand
      price
      image
      description
      category
      cylinder
      available
      createdAt
      updatedAt
    }
  }
`;
export const listProductsFirsts = /* GraphQL */ `
  query ListProductsFirsts(
    $filter: ModelProductsFirstFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductsFirsts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        brand
        price
        image
        description
        category
        cylinder
        available
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserInfo = /* GraphQL */ `
  query GetUserInfo($id: ID!) {
    getUserInfo(id: $id) {
      id
      name
      surname
      Lat
      Lng
      Address
      balance
      email
      phoneNumber
      createdAt
      updatedAt
      driver
      activeVoucherName
      activeVoucherCode
      discountPercentage
      discountAmount
      discountQty
    }
  }
`;
export const listUserInfos = /* GraphQL */ `
  query ListUserInfos(
    $filter: ModelUserInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        surname
        Lat
        Lng
        Address
        balance
        email
        phoneNumber
        createdAt
        updatedAt
        driver
        activeVoucherName
        activeVoucherCode
        discountPercentage
        discountAmount
        discountQty
      }
      nextToken
    }
  }
`;
export const getCarData = /* GraphQL */ `
  query GetCarData($id: ID!) {
    getCarData(id: $id) {
      id
      userID
      model
      make
      numberPlate
      colour
      createdAt
      updatedAt
    }
  }
`;
export const listCarData = /* GraphQL */ `
  query ListCarData(
    $filter: ModelCarDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarData(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        model
        make
        numberPlate
        colour
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCartProductFirst = /* GraphQL */ `
  query GetCartProductFirst($id: ID!) {
    getCartProductFirst(id: $id) {
      id
      userSub
      quantity
      productID
      title
      price
      image
      brand
      cylinder
      category
      createdAt
      updatedAt
    }
  }
`;
export const listCartProductFirsts = /* GraphQL */ `
  query ListCartProductFirsts(
    $filter: ModelCartProductFirstFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCartProductFirsts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userSub
        quantity
        productID
        title
        price
        image
        brand
        cylinder
        category
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      userSub
      orderAmount
      paymentStatus
      deliveryStatus
      fullName
      phoneNumber
      address
      status
      statusB
      statusC
      lat
      lng
      country
      city
      amount
      qty
      driverID
      driverSub
      car
      model
      plate
      driverName
      driverSurname
      driverImage
      type
      steps
      delTime
      delDistance
      createdAt
      updatedAt
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userSub
        orderAmount
        paymentStatus
        deliveryStatus
        fullName
        phoneNumber
        address
        status
        statusB
        statusC
        lat
        lng
        country
        city
        amount
        qty
        driverID
        driverSub
        car
        model
        plate
        driverName
        driverSurname
        driverImage
        type
        steps
        delTime
        delDistance
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrderProduct = /* GraphQL */ `
  query GetOrderProduct($id: ID!) {
    getOrderProduct(id: $id) {
      id
      quantity
      price
      image
      title
      brand
      titilet
      cylinder
      productID
      orderID
      createdAt
      updatedAt
    }
  }
`;
export const listOrderProducts = /* GraphQL */ `
  query ListOrderProducts(
    $filter: ModelOrderProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        quantity
        price
        image
        title
        brand
        titilet
        cylinder
        productID
        orderID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEmergencyPrices = /* GraphQL */ `
  query GetEmergencyPrices($id: ID!) {
    getEmergencyPrices(id: $id) {
      id
      title
      image
      price
      available
      category
      createdAt
      updatedAt
    }
  }
`;
export const listEmergencyPrices = /* GraphQL */ `
  query ListEmergencyPrices(
    $filter: ModelEmergencyPricesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmergencyPrices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        image
        price
        available
        category
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessageList = /* GraphQL */ `
  query GetMessageList($id: ID!) {
    getMessageList(id: $id) {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const listMessageLists = /* GraphQL */ `
  query ListMessageLists(
    $filter: ModelMessageListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessageLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        message
        backgroundColor
        letter
        author
        read
        date
        time
        userSub
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatRoom = /* GraphQL */ `
  query GetChatRoom($id: ID!) {
    getChatRoom(id: $id) {
      id
      driverID
      userID
      started
      createdAt
      updatedAt
    }
  }
`;
export const listChatRooms = /* GraphQL */ `
  query ListChatRooms(
    $filter: ModelChatRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        driverID
        userID
        started
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatMessage = /* GraphQL */ `
  query GetChatMessage($id: ID!) {
    getChatMessage(id: $id) {
      id
      chatRoomID
      message
      sender
      receiver
      createdAt
      updatedAt
    }
  }
`;
export const listChatMessages = /* GraphQL */ `
  query ListChatMessages(
    $filter: ModelChatMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        chatRoomID
        message
        sender
        receiver
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotificationsList = /* GraphQL */ `
  query GetNotificationsList($id: ID!) {
    getNotificationsList(id: $id) {
      id
      title
      message
      backgroundColor
      letter
      author
      read
      date
      time
      userSub
      createdAt
      updatedAt
    }
  }
`;
export const listNotificationsLists = /* GraphQL */ `
  query ListNotificationsLists(
    $filter: ModelNotificationsListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationsLists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        message
        backgroundColor
        letter
        author
        read
        date
        time
        userSub
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCarList = /* GraphQL */ `
  query GetCarList($id: ID!) {
    getCarList(id: $id) {
      id
      car
      name
      surname
      model
      plate
      brand
      createdAt
      updatedAt
    }
  }
`;
export const listCarLists = /* GraphQL */ `
  query ListCarLists(
    $filter: ModelCarListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCarLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        car
        name
        surname
        model
        plate
        brand
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDriverList = /* GraphQL */ `
  query GetDriverList($id: ID!) {
    getDriverList(id: $id) {
      id
      name
      surname
      image
      active
      lat
      lng
      userID
      car
      model
      plate
      createdAt
      updatedAt
    }
  }
`;
export const listDriverLists = /* GraphQL */ `
  query ListDriverLists(
    $filter: ModelDriverListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDriverLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        surname
        image
        active
        lat
        lng
        userID
        car
        model
        plate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVouchers = /* GraphQL */ `
  query GetVouchers($id: ID!) {
    getVouchers(id: $id) {
      id
      name
      amount
      createdAt
      updatedAt
    }
  }
`;
export const listVouchers = /* GraphQL */ `
  query ListVouchers(
    $filter: ModelVouchersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVouchers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        amount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUsedVouchers = /* GraphQL */ `
  query GetUsedVouchers($id: ID!) {
    getUsedVouchers(id: $id) {
      id
      userID
      voucherID
      createdAt
      updatedAt
    }
  }
`;
export const listUsedVouchers = /* GraphQL */ `
  query ListUsedVouchers(
    $filter: ModelUsedVouchersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsedVouchers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        voucherID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBannerList = /* GraphQL */ `
  query GetBannerList($id: ID!) {
    getBannerList(id: $id) {
      id
      title
      titletwo
      image
      description
      createdAt
      updatedAt
    }
  }
`;
export const listBannerLists = /* GraphQL */ `
  query ListBannerLists(
    $filter: ModelBannerListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBannerLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        titletwo
        image
        description
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrderReport = /* GraphQL */ `
  query GetOrderReport($id: ID!) {
    getOrderReport(id: $id) {
      id
      title
      description
      orderID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const listOrderReports = /* GraphQL */ `
  query ListOrderReports(
    $filter: ModelOrderReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        orderID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
