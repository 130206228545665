import React, {useState} from 'react'
import { createStackNavigator} from '@react-navigation/stack';
import { View } from 'react-native';
import UsersScreen from '../Screens/UsersScreen';
import UserDetails from '../Screens/UsersScreen/UserDetails';
import TopActivity from '../Screens/UsersScreen/TopActivity';
import StockMain from '../Screens/StockScreen.js/StockMain';
import OrdersScreen from '../Screens/OrdersScreen/OrdersScreen';
import TopActivityCompleted from '../Screens/OrdersScreen/TopActivityCompleted';
import ChatScreen from '../Screens/OrdersScreen/ChatScreen';

const Stack = createStackNavigator();

const OrdersStack = () => {

    return (
        <View style={{flex: 1}}>
            <Stack.Navigator
                screenOptions={{
                    headerShown: false
                    // header: () => <HeaderComponent searchValue={searchValue} setSearchValue={setSearchValue}/>
                }}
            >

                <Stack.Screen 
                
                name="Main">
                    {() => <OrdersScreen/>}
                </Stack.Screen>
                {/* <Stack.Screen component={UserDetails} name="UserDetails" options={{headerShown: false}} />*/}
                <Stack.Screen component={TopActivity} name="TopActivity" options={{headerShown: false}} /> 
                <Stack.Screen component={TopActivityCompleted} name="TopActivityCompleted" options={{headerShown: false}} /> 
                <Stack.Screen component={ChatScreen} name="ChatScreen" options={{headerShown: false}} />
            </Stack.Navigator>
        </View>
    );

};

export default OrdersStack;