import { View, Text, Pressable, TouchableOpacity, Image, Dimensions, FlatList, TextInput } from 'react-native'
import React, {useState, useEffect} from 'react';
import { useNavigation } from '@react-navigation/native'
import { Ionicons, MaterialCommunityIcons, Octicons, AntDesign, Entypo, MaterialIcons  } from '@expo/vector-icons'; 
import { API, graphqlOperation, Auth } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
 
const {width, height} = Dimensions.get('screen')

const Banners = () => {

    const navigation = useNavigation();
    const mainColour = '#FF4F00';

    const [bannerList, setBannerList] = useState([])



    async function banners() {

        const allTodos = await API.graphql({ query: queries.listBannerLists});

        const todos = allTodos.data.listBannerLists.items
        setBannerList(todos)


        console.log('items :', todos)

     }

     useEffect(() => {
      banners()

    }, [])

     const renderBanners = ({item})=> {


        const deleteBanner = async()=>{
            
            const deleteInput = {
                id: item?.id
            }

            await API.graphql({query: mutations.deleteBannerList, variables: {input: deleteInput}}).then((e)=> {
                    console.warn('deleted')
                
            })

        }   

        return(
            <View  style={{marginLeft: 15, height: 150, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    <Image style={{width: 160, height: 100, borderRadius: 10}} source={{uri: item?.image} || require('../../../assets/casual-life-3d-profile-picture-of-person-in-glasses-and-orange-shirt.png')}/>
                     <View style={{marginLeft: 25, width: width /2}}>
                        <Text>{item?.title}</Text>
                        <Text style={{fontSize: 12}}>{item?.titletwo}</Text>
                        <Text style={{fontSize: 12}}>{item?.description}</Text>
                    </View>

                    <TouchableOpacity onPress={()=> deleteBanner()} style={{width: 70, height: 40, marginLeft: 10, borderRadius: 10, backgroundColor: mainColour, alignItems: 'center', justifyContent: 'center'}}>
                        <Text style={{color: 'white', fontSize: 12}}>Delete</Text>
                    </TouchableOpacity>
                </View>
            </View>
        )

     }


  return (
    <View style={{flex: 1, backgroundColor: 'white'}}>
      <View style={{marginTop: 15, borderBottomWidth: 1, width: '100%', borderColor: '#d1d1d1', height: 45}}>
            <TouchableOpacity style={{marginLeft: 15}} onPress={()=> navigation.goBack()}>
                <Ionicons name="arrow-back-outline" size={24} color="black" />
            </TouchableOpacity>
        </View>

        <View style={{margin: 15}}>
               <TouchableOpacity onPress={()=> navigation.navigate('AddBanner')} style={{width: 120, height: 40, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderRadius: 5, backgroundColor: mainColour}}>
                    <Ionicons name="add-circle-sharp" size={24} color="white" />
                    <Text style={{color: 'white', fontSize: 12, marginLeft: 5}}>Add Banner</Text> 
                </TouchableOpacity>
          </View> 

        <View style={{margin: 15}}>
            <Text style={{fontSize: 18, fontWeight: 'bold'}}>Current Active Banners</Text>

            <FlatList 
                data={bannerList}
                renderItem={renderBanners}    
                style={{height: height/2.5}}
            />
        </View>

    </View>
  )
}

export default Banners