import { View, Text, FlatList, TouchableOpacity, Image, TextInput, ImageBackground } from 'react-native'
import React, {useState, useEffect} from 'react'
import { Entypo } from '@expo/vector-icons'; 
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons'; 
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { onProductsFirstUpdated, onProductsFirstAdded, onProductsFirstDeleted } from '../../graphql/subscriptions';
import uuid from 'react-native-uuid'; 
import { useNavigation } from '@react-navigation/native';
import { storage } from '../../../config';

// import { API, graphqlOperation, Storage, Auth } from 'aws-amplify';
import { API, graphqlOperation, Auth } from 'aws-amplify';

import * as ImagePicker from 'expo-image-picker';
// import * as Firebase from 'firebase'

import { getDownloadURL, ref, uploadBytes } from "firebase/storage";


const uii = uuid.v4();
const StockMain = () => {


    const navigation = useNavigation();

    const [selecCat, setSelecCat] = useState([])

    const [seleectedStock, setSelectedStock] = useState([])

    const [addingProd, setAddingProd] = useState(false)

    const [stockList, setStockList] = useState([])

    const [image, setImage] = useState(null);

    const [percentage, setPercentage] = useState(0);
    const [clicked, setClicked] = useState(false)
    const [updStock, setUpdStock] = useState([])

    const [title, setTitle] = useState()
    const [description, setDescription] = useState()
    const [brand, setBrand] = useState()
    const [price, setPrice] = useState()

    const [titleNew, setTitleNew] = useState()
    const [descriptionNew, setDescriptionNew] = useState()
    const [brandNew, setBrandNew] = useState()
    const [priceNew, setPriceNew] = useState()

    const [imgUrl, setImgUrl] = useState()

    const [categoryNew, setCategoryNew] = useState()

    const [available, setAvailable] = useState(true)



    const mainColour = '#FF4F00';

    const stockListb = [
        {id: 1,
        title: 'Petrol 93',
        brand: 'First Petroleum',
        price: 23,
        image: 'https://images.unsplash.com/photo-1616361715059-9daf3fdd50be?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=873&q=80',
        description: 'the description here is what will be written in detail.',
        category: 'petrol',
        available: true
        },
        {id: 2,
        title: 'Petrol 95',
        brand: 'First Petroleum',
        price: 24,
        image: 'https://images.unsplash.com/photo-1616361715059-9daf3fdd50be?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=873&q=80',
        description: 'the description here is what will be written in detail.',
        category: 'petrol',
        available: false
        },
        {id: 3,
        title: 'Diesel 550ppm',
        brand: 'First Petroleum',
        price: 22,
        image: 'https://images.unsplash.com/photo-1616361715059-9daf3fdd50be?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=873&q=80',
        description: 'the description here is what will be written in detail.',
        category: 'diesel',
        available: true
        },
        {id: 4,
        title: 'Ad-Blue',
        brand: 'First Petroleum',
        price: 295,
        image: 'https://car-images.bauersecure.com/pagefiles/79776/what_is_adblue_20.jpg',
        description: 'AdBlue is a diesel exhaust fluid used in vehicles with Selective Catalytic Reduction (SCR) technology to reduce harmful gases being released into the atmosphere. AdBlue is a 32,5 % solution of high-purity, synthetically manufactured urea in de-mineralized water. It is a safe-to-use fluid.',
        category: 'adblue',
        available: false
        },
        {id: 5,
        title: '200 Litres',
        brand: 'First Petroleum',
        price: 215,
        image: 'https://images.unsplash.com/photo-1613214293055-5678e2f6d7de?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80',
        description: '15W40 is a superior quality heavy-duty lubricant for diesel engines. It is blended from premium quality virgin base oils and advanced additives that offer the best possible lubrication. It assures excellent protection against high temperature engine deposits, oil degradation, and forms a thick oil film that protects engine parts from friction and wear.',
        category: 'lube',
        available: false
        },
        {id: 6,
        title: '15Kg',
        brand: 'First Petroleum',
        price: 215,
        image: 'https://images.unsplash.com/photo-1622308023558-2130696ec5cd?ixlib=rb-1.2.1&raw_url=true&q=80&fm=jpg&crop=entropy&cs=tinysrgb&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870',
        description: 'Liquefied petroleum gas is primarily propane but generally also contains significant amounts of olefins, which increase its reactivity substantially.',
        category: 'gas',
        available: false
        }
    ]

    const categoryList = [
     
        {   id: 1,
            title: 'petrol',
        },
        {   id: 2,
            title: 'diesel',
        },
        {   id: 3,
            title: 'gas',
        },
        {   id: 5,
            title: 'adblue',
        },
        {   id: 6,
            title: 'lube',
        },
       
    ]

//      useEffect(() => {
//     (async () => {
//       if (Platform.OS !== 'web') {
//         const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
//         if (status !== 'granted') {
//           alert('Sorry, we need camera roll permissions to make this work!');
//         }
//       }
//     })();
//   }, []);

    const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    console.log(result);

    // handleUpload(result)

    uploadImage(result)

    // setImageUri(result?.uri)
      
    if (!result.cancelled) {
      setImage(result?.uri);
    }
  };


//   start aws upload
  
//   const handleUpload = async(pickerResult)=>{
   

//     console.log('ui ', uii)

//     const urll = 'images/'+uii+'.jpg'
   
//     const img = await fetchImageFromUri(pickerResult.uri);
    
//     const uploadUrl = await uploadImage(urll, img);

//      downloadImage(uploadUrl);

   
//   }

//   const uploadImage = (filename, img) => {
//     Auth.currentCredentials();
//     return Storage.put(filename, img, {
//       level: "public",
//       contentType: "image/jpeg",
//       progressCallback(progress) {
//         setLoading(progress);
//       },
//     })
//       .then((response) => {
//         return response.key;
//       })
//       .catch((error) => {
//         console.log(error);
//         return error.response;
//       });
//   };

//   const setLoading = (progress) => {
//     const calculated = parseInt((progress.loaded / progress.total) * 100);
//     updatePercentage(calculated); // due to s3 put function scoped
//     console.log(calculated)
//   };

//     const updatePercentage = (number) => {
//     setPercentage(number);
//     console.log(number)
//   };

//    const downloadImage = (uri) => {
//     Storage.get(uri)
//       .then((result) => {setImage(result), console.log('img url',result), setImgUrl(result)})
//       .catch((err) => console.log(err));
      
//   };

//   const fetchImageFromUri = async (uri) => {
//     const response = await fetch(uri);
//     const blob = await response.blob();
//     return blob;
//   };

  // end aws upload





    // start firebase upload

    const uploadImage = async(pickerResult)=> {


        const blob = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function(){
                resolve(xhr.response);
            };
            xhr.onerror = function(){
                reject(new TypeError('Network request failed'));
            };
            xhr.responseType = 'blob';
            xhr.open('GET', pickerResult?.uri, true);
            xhr.send(null)

        });



        const datee = new Date().toISOString()

        const metadata = {
            contentType: 'image/jpeg',
        };

        const storageRef = ref(storage, datee);

        
        uploadBytes(storageRef, blob, metadata).then((snapshot) => {
        console.log('Uploaded')
        getDownloadURL(snapshot.ref).then((downloadURL) => {
            setImgUrl(downloadURL)
            console.log('d')
            // console.log('File available at', downloadURL);
        })
        }).catch((error) => {
            console.log(error.message)
        })    
       
    }



    async function fetchProducts() {

        const allTodos = await API.graphql({ query: queries.listProductsFirsts});

        const todos = allTodos.data.listProductsFirsts.items
        setStockList(todos)

     }

     useEffect(() => {
      fetchProducts()

    }, [updStock])



    let subsUpdate;
    let subsUpdateDel;
    let subsUpdateCreate;

    function setUpSus(){
   

     subsUpdate = API.graphql(graphqlOperation(onProductsFirstUpdated)).subscribe( {next: (daraa) => {
         setUpdStock(daraa)
     }, })
     
     subsUpdateDel = API.graphql(graphqlOperation(onProductsFirstDeleted)).subscribe( {next: (daraaa) => {
         setUpdStock(daraaa)
     }, }) 

     subsUpdateCreate = API.graphql(graphqlOperation(onProductsFirstAdded)).subscribe( {next: (daraaaaa) => {
         setUpdStock(daraaaaa)
     }, }) 

    }


    useEffect(() =>{
        setUpSus();

        return() =>{
            subsUpdate.unsubscribe();
            subsUpdateDel.unsubscribe();
            subsUpdateCreate.unsubscribe();
        };

    },[]);

    const renderCat = ({item})=> {
        return(
            <View style={{marginRight: 10}}>
                <TouchableOpacity onPress={()=> setSelecCat(item) } style={{width: 120, height: 40, alignItems: 'center', justifyContent: 'center', borderRadius: 5, backgroundColor: item?.id == selecCat?.id ? mainColour : 'black' }}>
                    <Text style={{color: 'white', fontSize: 16}}>{item?.title.toUpperCase()}</Text>
                    
                </TouchableOpacity>
            </View>
        )
    }

    const renderCatAdd = ({item})=> {
        return(
            <View style={{marginRight: 10}}>
                <TouchableOpacity onPress={()=> {setCategoryNew(item?.title), setClicked(true)}} style={{width: 120, height: 40, alignItems: 'center', justifyContent: 'center', borderRadius: 5, backgroundColor: item?.title == categoryNew ? mainColour : 'black' }}>
                    <Text style={{color: 'white', fontSize: 16}}>{item?.title.toUpperCase()}</Text>
                    
                </TouchableOpacity>
            </View>
        )
    }

    const lineSperate = () =>{
        return(
            <View style={{height: 1, width: '100%', backgroundColor: '#d1d1d1'}} />
        )
    }


    const addProd = async()=>{
         const inputNew = {
              image: imgUrl,
              title: titleNew,
              brand: brandNew,
              price: priceNew,
              description: descriptionNew,
              category: categoryNew,
              available: true
            }

        await API.graphql({query: mutations.createProductsFirst, variables: {input: inputNew}}).then((e)=> {setAddingProd(false)})
    
    }




    if(addingProd === true){

        const showingAddButton = () => {
            if(clicked === true){
                return(
                    <TouchableOpacity onPress={()=> addProd() } style={{height: 40, marginTop: 15, width: 80, borderRadius: 10, alignItems: 'center', justifyContent: 'center', backgroundColor: mainColour}}>
                            <Text style={{color: 'white', fontWeight: '800'}}>Add</Text>
                        </TouchableOpacity>
                )
            } else {
                return(
                    <View  style={{height: 40, marginTop: 15, width: 80, borderRadius: 10, alignItems: 'center', justifyContent: 'center', backgroundColor: 'gray'}}>
                            <Text style={{color: 'white', fontWeight: '800'}}>Add</Text>
                        </View>
                )
            }
        }


        return(
            <View style={{flex: 1, backgroundColor: '#ffffff'}}>
                <View style={{marginTop: 15}}>
                    <TouchableOpacity style={{marginLeft: 15}} onPress={()=> setAddingProd(false)}>
                        <Ionicons name="arrow-back-outline" size={24} color="black" />
                    </TouchableOpacity>


                    <View style={{marginTop: 25, flexDirection: 'row', alignSelf: 'center'}}>

                        <TouchableOpacity onPress={()=> pickImage()} style={{paddingLeft: 0}}>
                        <ImageBackground style={{width: 300, height: 300, borderRadius: 10, alignItems: 'center',justifyContent: 'center', borderWidth: 1, borderColor: '#d1d1d1'}} imageStyle={{borderRadius: 10}}  source={{uri: image}}>
                            <View style={{width: 30, height: 30, borderRadius: 50, backgroundColor: 'black', alignItems: 'center', justifyContent: 'center'}}>
                                <Entypo name="camera" size={15} color= 'white' />
                            </View>
                        </ImageBackground>
                        </TouchableOpacity>

                        <View style={{marginLeft: 40}}>
                        <View style={{flexDirection: 'row'}}>

                        
                        <View>
                            <Text style={{fontSize: 13, fontWeight: '800'}}>Title</Text>
                            <TextInput value= {titleNew} onChangeText = {setTitleNew} style={{width: 150, height: 45, marginTop: 5, borderRadius: 5, borderColor: '#d1d1d1', borderWidth: 1, paddingLeft: 10}} />   
                        </View>

                         <View style={{marginTop: 0, marginLeft: 20}}>
                            <Text style={{fontSize: 13, fontWeight: '800'}}>Description</Text>
                            <TextInput value= {descriptionNew} onChangeText={setDescriptionNew} style={{width: 150, height: 80, marginTop: 5, borderRadius: 5, borderColor: '#d1d1d1', borderWidth: 1, paddingLeft: 10}} />   
                        </View>

                        </View>

                       <View style={{flexDirection: 'row'}}>

                       <View style={{marginTop: 15}}>
                            <Text style={{fontSize: 13, fontWeight: '800'}}>Brand</Text>
                            <TextInput keyboardAppearance='numeric' value= {brandNew} onChangeText={setBrandNew} style={{width: 150, height: 45, marginTop: 5, borderRadius: 5, borderColor: '#d1d1d1', borderWidth: 1, paddingLeft: 10}} />   
                        </View>

                        <View style={{marginTop: 15, marginLeft: 20}}>
                            <Text style={{fontSize: 13, fontWeight: '800'}}>Price (PULA) - Number only</Text>
                            <TextInput keyboardAppearance='numeric' value= {priceNew} onChangeText={setPriceNew} style={{width: 150, height: 45, marginTop: 5, borderRadius: 5, borderColor: '#d1d1d1', borderWidth: 1, paddingLeft: 10}} />   
                        </View>

                        </View>

                        {/* category */}
                        <View style={{marginTop: 10}}>
                            <FlatList 
                                data={categoryList}
                                keyExtractor={(item, index) => index.toString()}
                                horizontal
                                renderItem={renderCatAdd}
                            />
                        </View>
                        
                        {showingAddButton()}
                     
                    </View>

                    </View>




                </View>


            </View>
        )
    }


    const renderStock = ({item}) => {

        if(item?.category === selecCat?.title){
        return(
            <View style={{ borderBottomWidth: 1, borderColor: '#d1d1d1'}}>
                <TouchableOpacity onPress={()=> setSelectedStock(item)} style={{padding: 20, flexDirection: 'row'}}>
                    <Image style={{height: 75, width: 75, borderRadius: 5, borderWidth: 3, borderColor: item?.id == seleectedStock?.id ? mainColour : 'white'}} source={{uri: item?.image}}/>
                    <View style={{marginLeft: 20}}>
                        <Text style={{fontSize: 16, fontWeight: 'bold'}}>{item?.title}</Text>
                        <Text style={{ fontSize: 13}}>{item?.brand}</Text>
                    <Text style={{ fontSize: 13,}}>P{Number(item?.price).toFixed(2)}</Text>
                    </View>
                </TouchableOpacity>
            </View>
        )
        } else {
            return(
                <View style={{height: 0, backgroundColor: 'white'}}/>
            )
        }
    }


    const showingAvail = ()=> {

        if(seleectedStock?.available === true){
             return(
                    <View>
                        <TouchableOpacity onPress={()=> updateUnAvail()} style={{height: 40, width: 170, borderRadius: 10, alignItems: 'center', justifyContent: 'center', backgroundColor: 'red'}}>
                                <Text style={{color: 'white', fontWeight: '800'}}>Set to Unavailable</Text>
                            </TouchableOpacity>
                    </View>
                )
   
        } else if(seleectedStock?.available === false){
            return(
                <View>
                        <TouchableOpacity onPress={()=> updateAvail()} style={{height: 40, width: 170, borderRadius: 10, alignItems: 'center', justifyContent: 'center', backgroundColor: 'green'}}>
                                <Text style={{color: 'white', fontWeight: '800'}}>Set to Available</Text>
                            </TouchableOpacity>
                    </View>
            )
        }
        
    }


    const updateProd= async() =>{
         const inputUpdate = {
              id: seleectedStock?.id,
            //   image: 'https://images.unsplash.com/photo-1560015534-cee980ba7e13?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=435&q=80',
              title: title,
              brand: brand,
              price: price,
              description: description,
            }
             await API.graphql({query: mutations.updateProductsFirst, variables: {input: inputUpdate}}).then((e)=> {})

    } 

    const updateAvail = async()=> {
        
         const inputUpdate = {
              id: seleectedStock?.id,
              available: true
         }

        await API.graphql({query: mutations.updateProductsFirst, variables: {input: inputUpdate}}).then((e)=> fetchProducts())

    }

    const updateUnAvail = async()=> {
        
         const inputUpdate = {
              id: seleectedStock?.id,
              available: false
         }

        await API.graphql({query: mutations.updateProductsFirst, variables: {input: inputUpdate}}).then((e)=> {})

    }

    const deleteProd = async()=> {
        
         const inputUpdate = {
              id: seleectedStock?.id,
         }

        await API.graphql({query: mutations.deleteProductsFirst, variables: {input: inputUpdate}}).then((e)=> {})

    }

  return (
    <View style={{backgroundColor: 'white', flex: 1}}>
      
      <View style={{margin: 15, height: '10%', flexDirection: 'row'}}>
          <FlatList 
              data={categoryList}
              keyExtractor={(item, index) => index.toString()}
              horizontal
              renderItem={renderCat}
          />

           <View style={{flexDirection: 'row'}}>
               <TouchableOpacity onPress={()=> setAddingProd(true)} style={{width: 120, height: 40, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderRadius: 5, backgroundColor: mainColour}}>
                    <Ionicons name="add-circle-sharp" size={24} color="white" />
                    <Text style={{color: 'white', fontSize: 12, marginLeft: 5}}>Add Product</Text> 
                </TouchableOpacity>

                <TouchableOpacity onPress={()=> navigation.navigate('EmergencyMain')} style={{width: 120, height: 40, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', borderRadius: 5, backgroundColor: 'red', marginLeft: 15}}>
                    <MaterialCommunityIcons name="fuel" size={24} color="white" />
                    <Text style={{color: 'white', fontSize: 12, marginLeft: 5}}>Emergency</Text> 
                </TouchableOpacity>
          </View> 
      </View>

      <View style={{width: '100%', height: '90%', flexDirection: 'row'}}>
          

        <View style={{marginTop: 0, marginLeft: 15, width: '40%', height: '100%', borderRightWidth: 1, borderColor: '#d1d1d1'}}>
            <FlatList 
                data={stockList}
                keyExtractor={(item, index) => index.toString()}
                renderItem={renderStock}
                //    ItemSeparatorComponent={lineSperate}
            />
        </View>

        <View style={{width: '60%', height: '100%'}}>
            <View style={{marginLeft: 15}}>
                <Text style={{fontSize: 20, fontWeight: 'bold'}}>{seleectedStock?.title}</Text>

                <View style={{marginTop: 15, flexDirection: 'row'}}>

                    <View>
                    <TouchableOpacity>
                        <ImageBackground style={{width: 200, height: 200, borderRadius: 10, alignItems: 'center',justifyContent: 'center'}} imageStyle={{borderRadius: 10}}  source={{uri: seleectedStock?.image}}>
                            <View style={{width: 30, height: 30, borderRadius: 50, backgroundColor: 'black', alignItems: 'center', justifyContent: 'center'}}>
                                <Entypo name="camera" size={15} color= 'white' />
                            </View>
                        </ImageBackground>
                    </TouchableOpacity>

                    <View style={{marginTop: 15, flexDirection: 'row'}}>
                        <TouchableOpacity onPress={()=> updateProd()} style={{height: 40, width: 80, borderRadius: 10, alignItems: 'center', justifyContent: 'center', backgroundColor: mainColour}}>
                            <Text style={{color: 'white', fontWeight: '800'}}>Update</Text>
                        </TouchableOpacity>

                        <TouchableOpacity onPress={()=> deleteProd()} style={{height: 40, width: 80, borderRadius: 10, marginLeft: 10, alignItems: 'center', justifyContent: 'center', backgroundColor: 'red'}}>
                            <Text style={{color: 'white', fontWeight: '800'}}>Delete</Text>
                        </TouchableOpacity>
                    </View>

                        <View style={{marginTop: 10}}>
                            {/* <TouchableOpacity style={{height: 40, width: 170, borderRadius: 10, alignItems: 'center', justifyContent: 'center', backgroundColor: mainColour}}>
                                <Text style={{color: 'white', fontWeight: '800'}}>Set Available</Text>
                            </TouchableOpacity> */}
                            {showingAvail()}
                        </View>
                    </View>
                    <View style={{marginLeft: 15}}>
                       <View style={{flexDirection: 'row'}}>

                      
                            <View>
                                <Text style={{fontSize: 13, fontWeight: '800'}}>Title</Text>
                                <TextInput value= {title} placeholder= {seleectedStock?.title} onChangeText={setTitle} style={{width: 150, height: 45, marginTop: 5, borderRadius: 5, borderColor: '#d1d1d1', borderWidth: 1, paddingLeft: 10}} />   
                            </View>

                            <View style={{marginTop: 0, marginLeft: 20}}>
                                <Text style={{fontSize: 13, fontWeight: '800'}}>Description</Text>
                                <TextInput value= {description} placeholder={seleectedStock?.description} onChangeText={setDescription} style={{width: 200, height: 80, marginTop: 5, borderRadius: 5, borderColor: '#d1d1d1', borderWidth: 1, paddingLeft: 10}} />   
                            </View>
                        </View>

                        <View style={{flexDirection: 'row'}}>

                        
                        <View style={{marginTop: 15}}>
                            <Text style={{fontSize: 13, fontWeight: '800'}}>Brand</Text>
                            <TextInput value= {brand} placeholder={seleectedStock?.brand} onChangeText={setBrand} style={{width: 150, height: 45, marginTop: 5, borderRadius: 5, borderColor: '#d1d1d1', borderWidth: 1, paddingLeft: 10}} />   
                        </View>

                        <View style={{marginTop: 15, marginLeft: 20}}>
                            <Text style={{fontSize: 13, fontWeight: '800'}}>Price (PULA)</Text>
                            <TextInput keyboardAppearance='numeric' value= {price} placeholder={seleectedStock?.price} onChangeText={setPrice} style={{width: 200, height: 45, marginTop: 5, borderRadius: 5, borderColor: '#d1d1d1', borderWidth: 1, paddingLeft: 10}} />   
                        </View>
                        </View>
                    </View>
                </View>
            </View>
        </View>

       
      </View>


    </View>
  )
}

export default StockMain